import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from './shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TeacherDashboardGuard } from './@shared/guards/teacher-dashboard.guard';
import { AuthenticationGuard } from './@shared/guards/authentication.guard';
import { HomePageGuard } from './@shared/guards/home-page.guard';
import { AdminPanelGuard } from './@shared/guards/admin-panel.guard';
import { APP_ROUTES } from './app.routes';

const routes: Routes = [
  Shell.childRoutes([{ path: '', children: [], canActivate: [HomePageGuard] }]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.Waiting,
      loadComponent: () =>
        import('@app/waiting/waiting.component').then(
          (c) => c.WaitingComponent
        ),
      data: {
        title: marker('Waiting'),
        menuHidden: true,
      },
    },
  ]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.TainScroller,
      loadChildren: () =>
        import('@app/games/train-scroller-game/train-scroller.routes').then(
          (c) => c.TRAIN_SCROLLER_ROUTES
        ),
      data: { title: marker('Stay on Track!') },
    },
  ]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.Stickers,
      loadChildren: () =>
        import('@app/games/stickerbook/stickerbook.routes').then(
          (c) => c.STICKERBOOK_ROUTES
        ),
      data: { title: marker('Sticker Book') },
    },
  ]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.TeacherDashboard,
      loadChildren: () =>
        import('@app/teacher-dashboard/teacher-dashboard.module').then(
          (m) => m.TeacherDashboardModule
        ),
      data: { title: marker('Teacher Dashboard'), menuHidden: false },
      canActivate: [TeacherDashboardGuard],
    },
  ]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.AdminPanel,
      loadChildren: () =>
        import('@app/admin-panel/admin-panel.module').then(
          (m) => m.AdminPanelModule
        ),
      data: { title: marker('Admin Panel'), menuHidden: false },
      canActivate: [AdminPanelGuard],
    },
  ]),
  Shell.childRoutes([
    {
      path: 'home',
      loadChildren: () =>
        import('@app/clubhouse/clubhouse.routes').then(
          (m) => m.CLUBHOUSE_ROUTES
        ),
      data: { title: marker('Home') },
      canActivate: [AuthenticationGuard],
    },
  ]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.Library,
      loadChildren: () =>
        import('@app/library/library.routes').then((m) => m.LIBRARY_ROUTES),
      data: { title: marker('Library') },
      canActivate: [AuthenticationGuard],
    },
  ]),

  Shell.childRoutes([
    {
      path: APP_ROUTES.Madlibs,
      loadChildren: () =>
        import('@app/games/madlibs/madlibs.routes').then(
          (m) => m.MADLIBS_ROUTES
        ),
      data: { title: marker('Silly Stories') },
      canActivate: [AuthenticationGuard],
    },
  ]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.Player,
      loadChildren: () =>
        import('./player/player.routes').then((mod) => mod.PLAYER_ROUTES),
    },
  ]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.Games,
      loadChildren: () =>
        import('@app/games/games.routes').then((m) => m.GAMES_ROUTES),
      data: { title: marker('Games') },
      canActivate: [AuthenticationGuard],
    },
  ]),
  Shell.childRoutes([
    {
      path: APP_ROUTES.Preview,
      loadChildren: () =>
        import('@app/preview/preview.module').then((m) => m.PreviewModule),
      data: { title: marker('Preview'), menuHidden: true },
    },
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      useHash: true,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
