<div class="login-container">
  <img
    src="/assets/images/hub/logo2.png"
    class="title img-fluid animate__animated animate__jackInTheBox"
  />
  <img
    src="/assets/images/hub/signin_kids_mod_trans_crop.png"
    class="kids img-fluid animate__animated animate__jackInTheBox"
  />
  <ng-container *ngIf="isOLlogin; then loading; else login"></ng-container>
</div>

<ng-template #loading>
  <app-loader [isLoading]="isLoading"></app-loader>
</ng-template>

<ng-template #login>
  <div class="login-box px-3 pb-5">
    <form (ngSubmit)="loginViaForm()" [formGroup]="loginForm" novalidate>
      <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
        Username or password incorrect.
      </div>
      <div class="input-wrapper mb-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            formControlName="username"
            autocomplete="username"
            [attr.disabled]="isLoading || null"
            [placeholder]="'Username' | translate"
          />
          <label
            for="floatingInput"
            [ngClass]="{
              'text-danger':
                loginForm.controls['username'].touched &&
                loginForm.controls['username'].invalid
            }"
            translate
          >
            Name
          </label>
        </div>
        <div class="form-floating">
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            formControlName="password"
            autocomplete="current-password"
            [attr.disabled]="isLoading || null"
            [placeholder]="'Password' | translate"
            required
          />
          <label
            for="floatingPassword"
            [ngClass]="{
              'text-danger':
                loginForm.controls['password'].touched &&
                loginForm.controls['password'].invalid
            }"
            translate
          >
            Password
          </label>
        </div>
      </div>
      <div class="button-container">
        <button
          class="btn btn-primary w-50 btn-lg"
          type="submit"
          [disabled]="loginForm.invalid || isLoading"
          appClickSound
          appWhooshSound
        >
          <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
          <span translate>Login</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
