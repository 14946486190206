import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/teacher-dashboard/dialogs/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogHelperService {
  private static defaultWarnMessage =
    'Changes may be lost - Are you sure you want to discard changes?';

  constructor(private dialog: MatDialog) {}

  public confirmDialog(
    message: string,
    yes: string = 'Confirm',
    no: string = 'Cancel'
  ): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          message,
          yes,
          no,
        },
        autoFocus: false,
      })
      .afterClosed();
  }

  public warnOnBackdropClick<T>(
    dialogRef: MatDialogRef<T>,
    message: string = DialogHelperService.defaultWarnMessage
  ): void {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.data = { message };

    dialogRef.backdropClick().subscribe(() => {
      this.dialog
        .open(ConfirmDialogComponent, config)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            dialogRef.close();
          }
        });
    });
  }
}
