export interface Data {
  packs: Pack[];
  pages: Page[];
  saved: StickerSaved[];
}
export interface Pack {
  id: number;
  name: string;
  stickers: Sticker[];
}

export interface Page {
  id: number;
  name: string;
  src: string;
  active?: boolean;
  enabled: boolean;
}

export interface Sticker {
  name: string;
  src: string;
  top: number;
  left: number;
  zIndex?: number;
  rotation?: number;
  size?: number;
  showMenu?: boolean;
  // id?: number;
  uuid?: string;
  pageId?: number;
}
export interface StickerSaved {
  // id: number;
  pageId: number;
  name: string;
  src: string;
  top: number;
  left: number;
  size: number;
  zIndex: number;
  rotation: number;
  showMenu: boolean;
  uuid: string;
}

export interface GameStateSaved {
  myShowPanel: boolean;
  myActivePackId: number;
  myActivePageSrc: string;
  myActivePageId?: number;
  mySaved: StickerSaved[];
}

export class stickersData {
  public static data: Data = {
    packs: [
      {
        id: 1,
        name: '🐕 Animals',
        stickers: [
          {
            name: 'Turtle',
            src: 'assets/images/stickerbook/stickers/animals/madlibs_stickers_astrangesafari_c_hide.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Dancing Dog',
            src: 'assets/images/stickerbook/stickers/animals/madlibs_stickers_astrangesafari_dancing-dog.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Cat',
            src: 'assets/images/stickerbook/stickers/animals/madlibs_stickers_astrangesafari_eating-cat.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Elephant',
            src: 'assets/images/stickerbook/stickers/animals/madlibs_stickers_astrangesafari_eating-elephant.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Giraffe',
            src: 'assets/images/stickerbook/stickers/animals/madlibs_stickers_astrangesafari_eating-giraffe.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Cat',
            src: 'assets/images/stickerbook/stickers/animals/madlibs_stickers_astrangesafari_sleeping-cat.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Sleeping Dog',
            src: 'assets/images/stickerbook/stickers/animals/madlibs_stickers_astrangesafari_sleeping-dog.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Fish',
            src: 'assets/images/stickerbook/stickers/animals/blowfish-1.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Parrot',
            src: 'assets/images/stickerbook/stickers/animals/parrot.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Alligator',
            src: 'assets/images/stickerbook/stickers/animals/alligator.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Jellyfish',
            src: 'assets/images/stickerbook/stickers/animals/jellyfish.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Fish',
            src: 'assets/images/stickerbook/stickers/animals/blowfish-2.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Whale',
            src: 'assets/images/stickerbook/stickers/animals/whale-1.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Whale',
            src: 'assets/images/stickerbook/stickers/animals/whale-2.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Boar',
            src: 'assets/images/stickerbook/stickers/animals/boar.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Bird',
            src: 'assets/images/stickerbook/stickers/animals/bird.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Hammerhead',
            src: 'assets/images/stickerbook/stickers/animals/hammerhead.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Shark',
            src: 'assets/images/stickerbook/stickers/animals/shark.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Manatee',
            src: 'assets/images/stickerbook/stickers/animals/manatee.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Snake',
            src: 'assets/images/stickerbook/stickers/animals/snake.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Squid',
            src: 'assets/images/stickerbook/stickers/animals/squid.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Walrus',
            src: 'assets/images/stickerbook/stickers/animals/walrus.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Hermit Crab',
            src: 'assets/images/stickerbook/stickers/animals/hermit-crab.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Elephant',
            src: 'assets/images/stickerbook/stickers/animals/elephant.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Cow',
            src: 'assets/images/stickerbook/stickers/animals/cow.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Goat',
            src: 'assets/images/stickerbook/stickers/animals/goat.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Deer',
            src: 'assets/images/stickerbook/stickers/animals/deer.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Fox',
            src: 'assets/images/stickerbook/stickers/animals/fox.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Wolf',
            src: 'assets/images/stickerbook/stickers/animals/wolf.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Bull',
            src: 'assets/images/stickerbook/stickers/animals/bull.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Panda',
            src: 'assets/images/stickerbook/stickers/animals/panda.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Sheep',
            src: 'assets/images/stickerbook/stickers/animals/sheep.png',
            top: 0,
            left: 0,
          },
        ],
      },
      {
        id: 2,
        name: '🌻 Plants',
        stickers: [
          {
            name: 'Beach',
            src: 'assets/images/stickerbook/stickers/plants/madlibs_stickers_astrangesafari_a_beach.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Woods',
            src: 'assets/images/stickerbook/stickers/plants/madlibs_stickers_astrangesafari_a_wood.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Plants',
            src: 'assets/images/stickerbook/stickers/plants/cactus.png',
            top: 0,
            left: 0,
          },
          {
            name: '1 Leaf',
            src: 'assets/images/stickerbook/stickers/plants/leaf.png',
            top: 0,
            left: 0,
          },
          {
            name: '2 Leaves',
            src: 'assets/images/stickerbook/stickers/plants/leaf_2.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Monstera',
            src: 'assets/images/stickerbook/stickers/plants/monstera.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Mushroom',
            src: 'assets/images/stickerbook/stickers/plants/mushroom.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Palm',
            src: 'assets/images/stickerbook/stickers/plants/palm.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Happy Pot',
            src: 'assets/images/stickerbook/stickers/plants/plant.png',
            top: 0,
            left: 0,
          },
        ],
      },
      {
        id: 3,
        name: '🍎 Food',
        stickers: [
          {
            name: 'Donut',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_dreamhouse_donut.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Pizza',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_dreamhouse_pizza1.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Empanadas',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_huntedhouse_d_empanadas.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Tortillas',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_testkitchen_a_tortilla.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Salad',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_astrangesafari_c_eat.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Sandwich',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_huntedhouse_d_sandwich.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Orange',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_testkitchen_a_orange.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Onion',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_testkitchen_c_onion.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Carrot',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_testkitchen_c_carrot.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Broccoli',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_testkitchen_c_broccoli.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Soup',
            src: 'assets/images/stickerbook/stickers/food/madlibs_stickers_testkitchen_b_broth.png',
            top: 0,
            left: 0,
          },
        ],
      },
      {
        id: 4,
        name: '🏀 Sports',
        stickers: [
          {
            name: 'Baseball',
            src: 'assets/images/stickerbook/stickers/sports/madlibs_stickers_dreamhouse_baseball.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Basketball',
            src: 'assets/images/stickerbook/stickers/sports/basketball.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Pogo Stick',
            src: 'assets/images/stickerbook/stickers/sports/madlibs_stickers_astrangesafari_c_jump.png',
            top: 0,
            left: 0,
          },
        ],
      },
      {
        id: 5,
        name: '👚 Costumes',
        stickers: [
          {
            name: 'Noemi',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_a_noemi.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Rigo',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_a_rigo.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Romana',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_a_romana.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Chef Hat',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_b_chef-hat.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Cowboy Hat',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_b_cowboy-hat.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Firefighter Hat',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_b_firefighter-hat.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Star Glasses',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_c_blue-glasses.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Round Glasses',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_c_dark-glasses.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Flower Glasses',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_c_flowery-glasses.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Dog T-Shirt',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_d_dog-tshirt.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Giraffe T-Shirt',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_d_giraffe-tshirt.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Panda T-Shirt',
            src: 'assets/images/stickerbook/stickers/costumes/madlibs_stickers_costumecontest_d_panda-tshirt.png',
            top: 0,
            left: 0,
          },
        ],
      },
      {
        id: 6,
        name: '🔨 Stuff',
        stickers: [
          {
            name: 'Chair',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_astrangesafari_a_livingroom.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Store',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_astrangesafari_a_store.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Caryon',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_astrangesafari_b_crayon.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Pillow',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_astrangesafari_b_pillow.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Open Book',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_astrangesafari_c_read.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Book',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_dreamhouse_book.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Fork',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_dreamhouse_fork.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Ladder',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_dreamhouse_ladder.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Shovel',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_dreamhouse_shovel.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Bed',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_huntedhouse_a_bedroom.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Kitchen',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_huntedhouse_a_kitchen.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Couch',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_huntedhouse_a_livingroom.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Paper',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_testkitchen_a_paper.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Phone',
            src: 'assets/images/stickerbook/stickers/house/madlibs_stickers_testkitchen_a_phone.png',
            top: 0,
            left: 0,
          },
        ],
      },
      {
        id: 7,
        name: '💥 Sounds',
        stickers: [
          {
            name: 'Angry',
            src: 'assets/images/stickerbook/stickers/sounds/madlibs_stickers_huntedhouse_b_angry.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Fuzzy',
            src: 'assets/images/stickerbook/stickers/sounds/madlibs_stickers_huntedhouse_b_fuzzy.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Gosh',
            src: 'assets/images/stickerbook/stickers/sounds/madlibs_stickers_huntedhouse_c_gosh.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Kapow',
            src: 'assets/images/stickerbook/stickers/sounds/madlibs_stickers_huntedhouse_c_kapow.png',
            top: 0,
            left: 0,
          },
          {
            name: 'Yowza',
            src: 'assets/images/stickerbook/stickers/sounds/madlibs_stickers_huntedhouse_c_yowza.png',
            top: 0,
            left: 0,
          },
        ],
      },
    ],
    pages: [
      {
        id: 1,
        name: 'Beach',
        src: '/assets/images/stickerbook/backgrounds/ctc_sticker_bg_beach.jpg',
        enabled: true,
      },
      {
        id: 2,
        name: 'City',
        src: '/assets/images/stickerbook/backgrounds/ctc_sticker_bg_city.jpg',
        enabled: true,
      },
      {
        id: 3,
        name: 'Garden',
        src: '/assets/images/stickerbook/backgrounds/ctc_sticker_bg_garden.jpg',
        enabled: true,
      },
      {
        id: 4,
        name: 'Park',
        src: '/assets/images/stickerbook/backgrounds/ctc_sticker_bg_park.jpg',
        enabled: true,
      },
      {
        id: 5,
        name: 'Plains',
        src: '/assets/images/stickerbook/backgrounds/ctc_sticker_bg_plains.jpg',
        enabled: true,
      },
      {
        id: 6,
        name: 'Under the Sea',
        src: '/assets/images/stickerbook/backgrounds/ctc_sticker_bg_sea.jpg',
        enabled: true,
      },
      {
        id: 7,
        name: 'Wild West',
        src: '/assets/images/stickerbook/backgrounds/ctc_sticker_bg_west.jpg',
        enabled: true,
      },
      {
        id: 8,
        name: 'Woods',
        src: '/assets/images/stickerbook/backgrounds/ctc_sticker_bg_wood.jpg',
        enabled: true,
      },
    ],
    saved: [],
  };
}
