export class chaptersData {
  public static data = {
    chapters: [
      {
        name: 'CH 1',
        src: 'assets/images/library/books/chapters/CHAPTER01_00-COVER.jpg',
        thumbnail: 'assets/images/library/books/thumbnails/ch1.png',
        alt: 'Chapter 1',
        completed: true,
        available: true,
        codex: {
          id: 1,
          name: 'Guardians of the Librarium: Chapter 1',
          src: 'assets/images/library/books/chapters/CHAPTER01_00-COVER.jpg',
          alt: 'Guardians of the Librarium: Chapter 1',
          completed: true,
          timesCompleted: 3,
          available: true,
        },
        sideStories: [
          {
            id: 2,
            name: 'Garden for Room 8',
            src: 'assets/images/library/books/side-quests/gardenforroomeight_cover.jpg',
            alt: 'Garden for Room 8',
            completed: false,
            timesCompleted: 1,
            available: true,
          },
          {
            id: 3,
            name: 'Thomas Edison',
            src: 'assets/images/library/books/side-quests/thomasedison_cover.jpg',
            alt: 'Thomas Edison',
            completed: false,
            timesCompleted: 2,
            available: true,
          },
          {
            id: 4,
            name: 'Wilma Rudolph',
            src: 'assets/images/library/books/side-quests/WilmaRudolph_cover.jpg',
            alt: 'Wilma Rudolph',
            completed: false,
            timesCompleted: 0,
            available: true,
          },
        ],
      },
      {
        name: 'CH 2',
        src: 'assets/images/library/books/chapters/CHAPTER02_00-COVER.jpg',
        thumbnail: 'assets/images/library/books/thumbnails/ch2.png',
        alt: 'Chapter 2',
        completed: false,
        available: true,
        codex: {
          id: 5,
          name: 'Guardians of the Librarium: Chapter 2',
          src: 'assets/images/library/books/chapters/CHAPTER02_00-COVER.jpg',
          alt: 'Guardians of the Librarium: Chapter 2',
          completed: false,
          available: true,
        },
        sideStories: [
          {
            id: 6,
            name: 'A Project For Rigo',
            src: 'assets/images/library/books/side-quests/aprojectforrigo_cover.jpg',
            alt: 'A Project For Rigo',
            completed: false,
            available: false,
          },
          {
            id: 7,
            name: 'Bass Reeves: Greatest Lawman in the West',
            src: 'assets/images/library/books/side-quests/bassreeves_cover_revised_title.jpg',
            alt: 'Bass Reeves: Greatest Lawman in the West',
            completed: false,
            available: false,
          },
          {
            id: 8,
            name: 'The Case of the Missing Meaning',
            src: 'assets/images/library/books/side-quests/thecaseofthemissingmeaning_cover.jpg',
            alt: 'The Case of the Missing Meaning',
            completed: false,
            available: false,
          },
        ],
      },
      {
        name: 'CH 3',
        src: 'assets/images/library/books/chapters/CHAPTER03_00-COVER.jpg',
        thumbnail: 'assets/images/library/books/thumbnails/ch3.png',
        alt: 'Chapter 3',
        completed: false,
        available: false,
        codex: {
          id: 9,
          name: 'Guardians of the Librarium: Chapter 3',
          src: 'assets/images/library/books/chapters/CHAPTER03_00-COVER.jpg',
          alt: 'Guardians of the Librarium: Chapter 3',
          completed: false,
          available: false,
        },
        sideStories: [
          {
            id: 10,
            name: 'Lost Pet',
            src: 'assets/images/library/books/side-quests/lostpet_cover.jpg',
            alt: 'Lost Pet',
            completed: false,
            available: false,
          },
          {
            id: 11,
            name: 'Andre Talley',
            src: 'assets/images/library/books/side-quests/andretalley_cover.jpg',
            alt: 'Andre Talley',
            completed: false,
            available: false,
          },
          {
            id: 12,
            name: 'Emily, Washington, and the Bridge',
            src: 'assets/images/library/books/side-quests/emilywashingtonandthebridge_cover.jpg',
            alt: 'Emily, Washington, and the Bridge',
            completed: false,
            available: false,
          },
        ],
      },
      {
        name: 'CH 4',
        src: 'assets/images/library/books/chapters/CHAPTER04_00-COVER.jpg',
        thumbnail: 'assets/images/library/books/thumbnails/ch4.png',
        alt: 'Chapter 4',
        completed: false,
        available: false,
        codex: {
          id: 13,
          name: 'Guardians of the Librarium: Chapter 4',
          src: 'assets/images/library/books/chapters/CHAPTER04_00-COVER.jpg',
          alt: 'Guardians of the Librarium: Chapter 4',
          available: false,
          completed: false,
        },
        sideStories: [
          {
            id: 14,
            name: 'The Case of the Borrowed History',
            src: 'assets/images/library/books/side-quests/RML_cover.jpg',
            alt: 'The Case of the Borrowed History',
            available: false,
          },
          {
            id: 15,
            name: 'Burmese Beauty in America',
            src: 'assets/images/library/books/side-quests/burmesebeautyinamerica_cover.jpg',
            alt: 'Burmese Beauty in America',
            available: false,
          },
          {
            id: 16,
            name: 'Helen Keller',
            src: 'assets/images/library/books/side-quests/helenkeller_cover.jpg',
            alt: 'Helen Keller',
            available: false,
          },
        ],
      },
      {
        name: 'CH 5',
        src: 'assets/images/library/books/chapters/CHAPTER05_00-COVER.jpg',
        thumbnail: 'assets/images/library/books/thumbnails/ch5.png',
        alt: 'Chapter 5',
        completed: false,
        available: false,
        codex: {
          id: 17,
          name: 'Guardians of the Librarium: Chapter 5',
          src: 'assets/images/library/books/chapters/CHAPTER05_00-COVER.jpg',
          alt: 'Guardians of the Librarium: Chapter 5',
          available: false,
          completed: false,
        },
        sideStories: [
          {
            id: 18,
            name: "What's for Lunch?",
            src: 'assets/images/library/books/side-quests/Cover_lunch.jpg',
            alt: "What's for Lunch?",
            available: false,
          },
          {
            id: 19,
            name: 'Eugene Bullard',
            src: 'assets/images/library/books/side-quests/eugenebullard_cover.jpg',
            alt: 'Eugene Bullard',
            available: false,
          },
          {
            id: 20,
            name: 'Cecelia Payne-Gaposhkin',
            src: 'assets/images/library/books/side-quests/dcpg_cover.jpg',
            alt: 'Cecelia Payne-Gaposhkin',
            available: false,
          },
        ],
      },
      {
        name: 'CH 6',
        src: 'assets/images/library/books/chapters/CHAPTER06_00-COVER.jpg',
        thumbnail: 'assets/images/library/books/thumbnails/ch6.png',
        alt: 'Chapter 6',
        completed: false,
        available: false,
        codex: {
          id: 21,
          name: 'Guardians of the Librarium: Chapter 6',
          src: 'assets/images/library/books/chapters/CHAPTER06_00-COVER.jpg',
          alt: 'Guardians of the Librarium: Chapter 6',
          available: false,
          completed: false,
        },
        sideStories: [
          {
            id: 22,
            name: 'Bessie Stringfield',
            src: 'assets/images/library/books/side-quests/BESSIE-STRINGFIELD_00-COVER.jpg',
            alt: 'Bessie Stringfield',
            completed: false,
            available: false,
          },
          {
            id: 23,
            name: 'Either Be A Mountain Or Lean On One',
            src: 'assets/images/library/books/side-quests/Cover_mountain.jpg',
            alt: 'Either Be A Mountain Or Lean On One',
            completed: false,
            timesCompleted: 0,
            available: false,
          },
          {
            id: 24,
            name: 'Hide and Seek',
            src: 'assets/images/library/books/missing.png',
            alt: 'Hide and Seek',
          },
        ],
      },
      {
        name: 'CH 7',
        src: 'assets/images/library/books/chapters/CHAPTER07_00-COVER.jpg',
        thumbnail: 'assets/images/library/books/thumbnails/ch7.png',
        alt: 'Chapter 7',
        completed: false,
        available: false,
        codex: {
          id: 25,
          name: 'Guardians of the Librarium: Chapter 7',
          src: 'assets/images/library/books/chapters/CHAPTER07_00-COVER.jpg',
          alt: 'Guardians of the Librarium: Chapter 7',
          available: false,
          completed: false,
        },
        sideStories: [
          {
            id: 26,
            name: 'Hawaiian Rainforests',
            src: 'assets/images/library/books/side-quests/rainforest_cover.jpg',
            alt: 'Hawaiian Rainforests',
          },
          {
            id: 27,
            name: 'Romana Acosta Bañuelos',
            src: 'assets/images/library/books/side-quests/RomanaBanuelos_cover.jpg',
            alt: 'Romana Acosta Bañuelos',
          },
          {
            id: 28,
            name: 'Jaguars Unite',
            src: 'assets/images/library/books/side-quests/JaguarsUnite_cover.jpg',
            alt: 'Jaguars Unite',
          },
        ],
      },
      {
        name: 'CH 8',
        src: 'assets/images/library/books/chapters/CHAPTER08_00-COVER.jpg',
        thumbnail: 'assets/images/library/books/thumbnails/ch8.png',
        alt: 'Chapter 8',
        completed: false,
        available: false,
        codex: {
          id: 29,
          name: 'Guardians of the Librarium: Chapter 8',
          src: 'assets/images/library/books/chapters/CHAPTER08_00-COVER.jpg',
          alt: 'Guardians of the Librarium: Chapter 8',
          available: false,
          completed: false,
        },
        sideStories: [
          {
            id: 30,
            name: 'A Portrait Of Recovery',
            src: 'assets/images/library/books/side-quests/aportraitofrecovery_cover.jpg',
            alt: 'A Portrait Of Recovery',
            completed: false,
            timesCompleted: 0,
            available: false,
          },
          {
            id: 31,
            name: 'Gladys West',
            src: 'assets/images/library/books/side-quests/Cover_Image.jpg',
            alt: 'Gladys West',
          },
          {
            id: 32,
            name: 'Kano Jigoro',
            src: 'assets/images/library/books/side-quests/kj_cover.jpg',
            alt: 'Kano Jigoro',
          },
        ],
      },
    ],
  };
}
