<div>
  <button class="btn btn-icon light close-btn" mat-dialog-close>
    <i class="fa-regular fa-xmark"></i>
  </button>

  <div class="title">{{ isEditing ? 'Edit class' : 'Create new class' }}</div>

  <mat-dialog-content>
    <form class="input-form" [formGroup]="cohortForm">
      <div fxLayout="column" fxLayoutGap="20px">
        <div>
          <label for="title" translate>Title</label>
          <div>
            <input
              appTrimOnBlur
              [appErrorInput]="{
                required: 'Title is required',
                maxlength: 'Max length is ' + maxTitleLength,
                forbiddenValue: 'Title should differ from existing classes'
              }"
              [ngClass]
              type="text"
              id="title"
              formControlName="title"
            />
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="10">
          <div fxFlex>
            <label for="startDate" translate>Start date</label>
            <div class="form-group has-prefix">
              <input
                class="form-field date-picker-input"
                [min]="minStartDate"
                [matDatepickerFilter]="mondayFilter"
                matInput
                readonly
                (click)="datepicker.open()"
                id="startDate"
                formControlName="startDate"
                [matDatepicker]="datepicker"
                [appErrorInput]="{
                  required: 'Start date is required'
                }"
              />
              <mat-icon
                class="icon prefix"
                style="pointer-events: none"
                svgIcon="calendar"
              ></mat-icon>
            </div>

            <mat-datepicker #datepicker>
              <mat-datepicker-actions>
                <button
                  translate
                  class="btn btn-text light me-2"
                  matDatepickerCancel
                >
                  Cancel
                </button>
                <button translate class="btn btn-primary" matDatepickerApply>
                  Apply
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </div>

          <div fxFlex>
            <label for="program" translate>Program</label>
            <app-search-program-field
              formControlName="program"
              placeholder="Select program"
              [options]="programs$ | async"
              [appErrorInput]="{
                required: 'Program is required'
              }"
              (focused)="programSearchFocused$.next()"
            ></app-search-program-field>
          </div>
        </div>

        <div>
          <label for="storiesCompleteToUnlockGames" translate>
            Lock games until # stories completed
          </label>
          <mat-select
            formControlName="storiesCompleteToUnlockGames"
            class="form-field"
          >
            <ng-container *ngFor="let number of storiesToUnlockGames">
              <mat-option [value]="number">{{ number }}</mat-option>
            </ng-container>
          </mat-select>
        </div>

        <div
          fxLayoutGap="15px"
          fxLayoutAlign="space-between center"
          class="toggle"
        >
          <span class="text" translate>
            Complete main story to unlock side stories
          </span>
          <mat-slide-toggle
            formControlName="completeMainStoryRequired"
          ></mat-slide-toggle>
        </div>
      </div>

      <div class="my-4" fxLayoutGap="20px" fxLayoutAlign="end center">
        <button
          class="btn btn-text light"
          type="reset"
          appClickSound
          appWhooshSound
          matDialogClose
        >
          <span translate>Cancel</span>
        </button>

        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="cohortForm.invalid || isLoading"
          appClickSound
          appWhooshSound
          (click)="saveClicked()"
        >
          <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
          <span translate>{{ isEditing ? 'Save' : 'Create' }}</span>
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
