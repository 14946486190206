export interface Feedback {
  id: string;
  feedbackText: string;
  dateCreated: string;
  read: boolean;
  status: FeedbackStatus;
  type: FeedbackType;
  attachments: string[];
  adminNote: string;
  important: boolean;
  user: FeedbackUserInfo;
}

export interface FeedbackUserInfo {
  email: string;
  firstName: string;
  lastName: string;
}

export enum FeedbackType {
  ISSUE = 'ISSUE',
  SUGGESTION = 'SUGGESTION',
  GENERAL = 'GENERAL',
}

export enum FeedbackStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
