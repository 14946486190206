import { Injectable } from '@angular/core';

/**
 * This service can be used to bypass iOS audio issues by allowing you to
 * initialize an audio element that you can user later.
 */

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private audio: HTMLAudioElement;

  public init(): void {
    this.audio = new Audio();
  }

  public get(): HTMLAudioElement {
    if (!this.audio) {
      this.audio = new Audio();
    }
    return this.audio;
  }
}
