import { Injectable } from '@angular/core';
import { AuthenticationService, CredentialsService } from '@app/auth';
import {
  Observable,
  Subscription,
  combineLatest,
  fromEvent,
  interval,
  merge,
} from 'rxjs';
import {
  debounceTime,
  first,
  startWith,
  tap,
  filter,
  map,
} from 'rxjs/operators';
import { EventingService } from './eventing/eventing.service';
import { eventsFactory } from './eventing/eventing.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Logger } from '../logger.service';
import { environment } from '@env/environment';
import { inputIsNotNullOrUndefined, navigateToExternalUrl } from '../helpers';
import { StudentService } from './student-progress.service';
import { EventBusNames, EventBusService } from './event-bus.service';
import { EventData } from './event.class';
import { Router } from '@angular/router';

export const OL_KEEP_ALIVE_INTERVAL_MS = 5000; // 5 seconds
export const HEARTBEAT_INTERVAL_MS = 60000; // 1 minute
export const INACTIVITY_THRESHOLD_MS = 60000 * 5; // 5 minutes

const log = new Logger('UserActivityService');

@UntilDestroy()
@Injectable()
export class UserActivityService {
  private isAuthenticated$: Observable<boolean> =
    this.credsService.isAuthenticated$.pipe(
      untilDestroyed(this),
      tap((isAuthenticated) => {
        if (isAuthenticated) {
          this.startHeartbeat();
          this.startObservingUserActivity();
          this.startOLKeepAlive();
        } else {
          this.stopHeartbeat();
          this.stopObservingUserActivity();
          this.stopOLKeepAlive();
        }
      })
    );

  private onLogin$ = this.authService.onLogin.pipe(
    untilDestroyed(this),
    tap(() => this.startSession())
  );

  private onLogout$ = this.authService.onLogout.pipe(
    untilDestroyed(this),
    tap(() => this.closeSession())
  );

  /** We need to adjust the context of heartbeat data depending on what user is doing.
   * Currently we're observing game and story activities*/
  private activityData$ = merge(
    this.eventBus
      .eventReceived(EventBusNames.game)
      .pipe(map((event) => eventDataToParam('game', event))),
    this.eventBus
      .eventReceived(EventBusNames.story)
      .pipe(map((event) => eventDataToParam('story_id', event)))
  ).pipe(startWith(<any>null), untilDestroyed(this));

  private olKeepAliveSub$: Subscription | null = null;
  private hearbeatSub$: Subscription | null = null;
  private activitySub$: Subscription | null = null;

  constructor(
    private credsService: CredentialsService,
    private eventingService: EventingService,
    private authService: AuthenticationService,
    private studentService: StudentService,
    private eventBus: EventBusService,
    private router: Router
  ) {}

  public initialize(): void {
    this.isAuthenticated$.subscribe();
    this.onLogin$.subscribe();
    this.onLogout$.subscribe();
  }

  private startOLKeepAlive(): void {
    if (!this.olKeepAliveSub$) {
      this.hearbeatSub$ = interval(OL_KEEP_ALIVE_INTERVAL_MS).subscribe(() => {
        if (window.opener) {
          log.info('Send keep alive to window opener');
          window.opener.postMessage(
            'com.mheducation.openlearning.lti.keep_alive',
            '*'
          );
        }
      });
    }
  }

  private stopOLKeepAlive(): void {
    if (this.olKeepAliveSub$) {
      this.olKeepAliveSub$.unsubscribe();
      this.olKeepAliveSub$ = null;
    }
  }

  private startHeartbeat(): void {
    if (!this.hearbeatSub$) {
      if (this.credsService.isStudent) {
        this.hearbeatSub$ = combineLatest([
          this.studentService.studentData$.pipe(
            filter(inputIsNotNullOrUndefined),
            first()
          ),
          interval(HEARTBEAT_INTERVAL_MS).pipe(startWith(1)),
          this.activityData$,
        ]).subscribe(([{ classId }, , activityData]) => {
          this.eventingService.emit(
            eventsFactory.HEARTBEAT.create({
              class_id: classId,
              ...activityData,
            })
          );
        });
      } else {
        this.hearbeatSub$ = interval(HEARTBEAT_INTERVAL_MS).subscribe(() => {
          this.eventingService.emit(eventsFactory.HEARTBEAT.create());
        });
      }
    }
  }

  private stopHeartbeat(): void {
    if (this.hearbeatSub$) {
      this.hearbeatSub$.unsubscribe();
      this.hearbeatSub$ = null;
    }
  }

  private startObservingUserActivity(): void {
    this.activitySub$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
      fromEvent(document, 'keydown'),
      fromEvent(window, 'load')
    )
      .pipe(debounceTime(INACTIVITY_THRESHOLD_MS), first())
      .subscribe(() => {
        this.closeSession();
        this.stopHeartbeat();
        this.stopObservingUserActivity();
      });
  }

  private startSession(): void {
    this.eventingService.emit(eventsFactory.SESSION_START.create());
  }

  private closeSession(): void {
    const isAdmin = this.credsService.isAdmin;

    this.eventingService.emit(eventsFactory.SESSION_CLOSE.create());
    this.authService.clearUser();

    isAdmin
      ? this.router.navigate(['/login'])
      : navigateToExternalUrl(environment.loginUrl);
  }

  private stopObservingUserActivity(): void {
    this.activitySub$?.unsubscribe();
  }
}

function eventDataToParam(
  paramName: string,
  event: EventData
): { [key: string]: any } {
  return inputIsNotNullOrUndefined(event.value)
    ? {
        [paramName]: event.value,
      }
    : null;
}
