import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
  standalone: true,
})
export class SortByPipe implements PipeTransform {
  public transform(
    array: any[],
    propertyName: string,
    direction: 'asc' | 'desc' = 'asc'
  ): any[] {
    if (!array || !propertyName) {
      return array;
    }

    const sortedArray = array.slice().sort((a, b) => {
      const aValue = a[propertyName];
      const bValue = b[propertyName];

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });

    return sortedArray;
  }
}
