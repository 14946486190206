import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrimOnBlur]',
})
export class TrimOnBlurDirective {
  constructor(private el: ElementRef) {}

  @HostListener('blur')
  public onBlur(): void {
    const value = this.el.nativeElement.value.trim();
    this.el.nativeElement.value = value;
  }
}
