import { Component, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { inputIsTrue } from '@app/@shared/helpers';
import { DialogHelperService } from '@app/@shared/services/dialog-helper.service';
import {
  ParamsForGetPrograms,
  ProgramsService,
} from '@app/admin-panel/services/programs.service';
import { SearchResultModel } from '@app/teacher-dashboard/components/search-program/search-program-field/search-program-field.component';
import { CustomValidators } from '@app/teacher-dashboard/customValidators';
import { Cohort } from '@app/teacher-dashboard/models/cohort';
import {
  CohortData,
  CohortService,
  CohortUpdateModel,
} from '@app/teacher-dashboard/services/cohort.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, merge, of } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-create-cohort',
  templateUrl: './create-cohort.component.html',
  styleUrls: ['./create-cohort.component.scss'],
})
export class CreateCohortComponent implements OnInit {
  public cohortForm!: UntypedFormGroup;
  public isLoading: boolean = false;
  public isEditing: boolean = false;

  public programs$: Observable<SearchResultModel[]>;

  public programSearchFocused$ = new Subject();

  public readonly maxTitleLength: number = 255;
  public readonly storiesToUnlockGames: number[] = Array.from(
    { length: 10 },
    (_, i) => i + 1
  );

  private paramsForGetPrograms: ParamsForGetPrograms = {
    page: 1,
    limit: 100,
  };

  get title(): AbstractControl | null {
    return this.cohortForm.get('title');
  }

  get startDate(): AbstractControl | null {
    return this.cohortForm.get('startDate');
  }

  get program(): AbstractControl | null {
    return this.cohortForm.get('program');
  }

  get storiesCompleteToUnlockGames(): AbstractControl | null {
    return this.cohortForm.get('storiesCompleteToUnlockGames');
  }

  get completeMainStoryRequired(): AbstractControl | null {
    return this.cohortForm.get('completeMainStoryRequired');
  }

  get minStartDate(): Date {
    const now = new Date();
    const weekAgo = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7
    );
    if (this.isEditing) {
      const startDate = new Date(this.startDate.value);
      const max = startDate < weekAgo ? startDate : weekAgo;
      return max;
    }
    return weekAgo;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CohortData,
    public dialogRef: MatDialogRef<CreateCohortComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private cohortService: CohortService,
    private programsService: ProgramsService,
    private dialogHelper: DialogHelperService
  ) {
    this.dialogRef.disableClose = true;
    this.isEditing = data?.mode === 'update';
    this.createForm();
    this.subsribeToProgramSearch();
  }

  public ngOnInit(): void {
    this.dialogHelper.warnOnBackdropClick(this.dialogRef);
  }

  public mondayFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day === 1;
  };

  public get startDateChanged(): boolean {
    const initialDate = new Date(this.data.cohort.startDate).getTime();
    const currentDate = new Date(this.startDate.value).getTime();
    return initialDate !== currentDate;
  }

  public saveClicked(): void {
    let programChanged = false;
    if (this.program.value) {
      programChanged = this.program.value.id !== this.data.cohort.program?.id;
    }

    if (this.isEditing && (this.startDateChanged || programChanged)) {
      this.warnAboutProgramChanges();
      return;
    }
    this.saveCohort();
  }

  private createForm(): void {
    const cohort = this.data?.cohort;
    const existingCohorts = this.data.existingCohorts
      .filter((c) => c.id !== cohort.id)
      .map((c) => c.title);

    this.cohortForm = this.formBuilder.group({
      title: [
        cohort?.title ?? '',
        [
          Validators.required,
          Validators.maxLength(this.maxTitleLength),
          CustomValidators.notOneOf(existingCohorts ?? []),
        ],
      ],
      startDate: [
        new Date(cohort?.startDate) ?? new Date(),
        [Validators.required],
      ],
      program: [cohort?.program, [Validators.required]],
      completeMainStoryRequired: [cohort?.completeMainStoryRequired ?? true],
      storiesCompleteToUnlockGames: [
        cohort?.requireStoriesCompleteToUnlockGames ?? 3,
      ],
    });
  }

  private warnAboutProgramChanges(): void {
    this.dialogHelper
      .confirmDialog(
        'Changing date or program will imply the default class program. Do you wish to proceed?'
      )
      .pipe(filter(inputIsTrue))
      .subscribe(() => {
        this.saveCohort();
      });
  }

  private get startDateValue(): string {
    const startDate = this.startDate.value && new Date(this.startDate.value);
    if (startDate) {
      startDate.setHours(12, 0, 0, 0);
      return startDate.toISOString();
    }
  }

  private saveCohort(): void {
    this.isLoading = true;

    const updatedCohort: CohortUpdateModel = {
      title: this.title.value.trim(),
      grade: this.data.cohort.grade,
      id: this.data.cohort.id,
      completeMainStoryRequired: this.completeMainStoryRequired.value,
      requireStoriesCompleteToUnlockGames:
        this.storiesCompleteToUnlockGames.value,
      programId: this.program.value.id,
    };

    if (this.startDateChanged || !this.isEditing) {
      updatedCohort.startDate = this.startDateValue;
    }

    if (this.isEditing) {
      this.cohortService
        .updateClassById(updatedCohort, updatedCohort.id)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(this.saveCohortCallback.bind(this));
    } else {
      this.cohortService
        .createNewClass(updatedCohort)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(this.saveCohortCallback.bind(this));
    }
  }

  private subsribeToProgramSearch(): void {
    this.programs$ = merge(
      this.program.valueChanges,
      this.programSearchFocused$.pipe(map(() => ''))
    ).pipe(
      startWith(''),
      debounceTime(300),
      switchMap((keyword: any) => {
        this.paramsForGetPrograms.keyword = keyword;
        if (typeof keyword === 'string') {
          return this.programsService
            .getProgramsForCohort(this.paramsForGetPrograms)
            .pipe(
              map((response) => {
                return response.data.map((d) => {
                  return {
                    title: d.title,
                    id: d.id,
                  };
                });
              })
            );
        }
        return of([]);
      }),
      untilDestroyed(this)
    );
  }

  private saveCohortCallback(cohort: Cohort): void {
    this.isLoading = false;
    // This is needed because back does not return the title in response
    cohort.program.title = this.program.value.title;
    this.dialogRef.close(cohort);
  }
}
