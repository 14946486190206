import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VoiceOption } from '@app/games/train-scroller-game/train-scroller.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AudioPlaybackService {
  constructor(private http: HttpClient) {}

  public replayAudio(audio: HTMLAudioElement | undefined | null): void {
    if (!audio) {
      return;
    }

    if (audio.ended) {
      audio.currentTime = 0;
      audio.play();
      return;
    }

    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  }

  private getAudio(text: string, rate: number, voice: string): Observable<any> {
    return this.http.get<Blob>(
      `${environment.hubApi}/audio?text=${encodeURIComponent(
        text
      )}&rate=${rate}&voice=${voice}`,
      {
        responseType: 'blob' as 'json',
        observe: 'body',
        reportProgress: true,
      }
    );
  }

  public generateAudio(
    audio: HTMLAudioElement,
    sentences: string[],
    rate: number = 100,
    voice: VoiceOption = 'Kendra'
  ): Observable<HTMLAudioElement> {
    // join sentences and remove any html tags
    const combined = sentences.join(' ').replace(/<[^>]*>/gi, '');

    return this.getAudio(combined, rate, voice).pipe(
      map((data: Blob) => {
        const blob = new Blob([data], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);
        audio.src = url;
        audio.pause();
        return audio;
      })
    );
  }
}
