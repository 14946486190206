import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastComponent } from './toast/toast.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { InstructionsModalModule } from './components/instructions-modal/instructions-modal.module';
import { InstructionsComponent } from './components/instructions-modal/instructions/instructions.component';
import { DirectivesModule } from './directives/directives.module';
import { DialogWrapperComponent } from './components/dialog-wrapper/dialog-wrapper.component';
import { SortByPipe } from './pipes/sortBy/sort-by.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    MatDialogModule,
    ReactiveFormsModule,
    FlexModule,
    DirectivesModule,
    MatIconModule,
    InstructionsModalModule,
    LoaderComponent,
    DialogWrapperComponent,
    SortByPipe,
  ],
  declarations: [ToastComponent],
  exports: [
    ToastComponent,
    InstructionsComponent,
    LoaderComponent,
    DialogWrapperComponent,
    DirectivesModule,
    LoaderComponent,
  ],
})
export class SharedModule {}
