import { Story } from '@app/@shared/models/new-story';

export interface Program {
  id: string;
  title: string;
  enabled: boolean;
  createDate: string;
  storySets: StorySet[];
  creator: ProgramCreatorInfo;
}

export interface ProgramCreatorInfo {
  email: string;
  firstName: string;
  lastName: string;
}

export interface StorySet {
  startDate?: string;
  mainStory?: Story;
  sideStories: Story[];
  /**
   * Indicates whether the storySet was created in Admin panel or in Teacher dashboard.
   * Will be used to restrict some actions.
   * I.e a user cannot modify the main stories in the story sets newly created from the teacher dashboard.
   */
  isPredefined?: boolean;
}

export function defaultStorySet(
  date: Date | null = null,
  isPredefined: boolean = false
): StorySet {
  return {
    startDate: date?.toISOString(),
    isPredefined: isPredefined,
    sideStories: [],
  };
}

export function flattenStorySet(set: StorySet): Story[] {
  const result = [...set.sideStories];
  if (set.mainStory) {
    result.unshift(set.mainStory);
  }
  return result;
}
