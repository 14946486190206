import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { CredentialsService } from '../../auth/credentials.service';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { mergeMap } from 'rxjs/operators';
import { CeresService } from '@app/ceres/ceres.service';

const TOKEN_HEADER_KEY = 'authorization';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private credentialsService: CredentialsService,
    private ceresService: CeresService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.getToken(request).pipe(
      mergeMap((token) => {
        if (token) {
          request = request.clone({
            headers: request.headers.set(TOKEN_HEADER_KEY, `bearer ${token}`),
          });
        }
        return next.handle(request);
      })
    );
  }

  private getToken(request: HttpRequest<any>): Observable<string | undefined> {
    if (this.isHubRequest(request) || !this.isPrefixed(request)) {
      return of(this.credentialsService.credentials?.token);
    } else {
      //TODO: Remove this override logics when we have a separate environments for testing Stage and Prod Ceres
      // if (this.ceresService.token) {
      //   console.log('--------- CERES TOKEN IS OVERRIDEN');
      //   return of(this.ceresService.token);
      // }
      return this.credentialsService.fetchCeresToken();
    }
  }

  private isHubRequest(request: HttpRequest<any>): boolean {
    return request.url.includes(environment.hubApi);
  }

  // Requests to fetch svg assets do not have http prefix.
  private isPrefixed(request: HttpRequest<any>): boolean {
    return /^(http|https):/i.test(request.url);
  }
}
