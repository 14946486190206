import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DirectivesModule } from '@app/@shared/directives/directives.module';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogModule, FlexLayoutModule, DirectivesModule],
})
export class DialogWrapperComponent {
  @Input() public title?: string | undefined;
  @Input() public cancelButtonTitle: string = 'Cancel';
  @Input() public showsCancelButton: boolean = true;
  @Input() public saveButtonTitle: string = 'Save';
  @Input() public showsSaveButton: boolean = true;
  @Input() public saveButtonDisabled: boolean = false;
  @Input() public cancelButtonDisabled: boolean = false;
  @Input() public saveButtonId?: string;
  @Input() public isLoading: boolean = false;

  @Output() public saveClicked = new EventEmitter();
  @Output() public cancelClicked = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<any>) {}

  public onCancelClicked(): void {
    if (this.cancelClicked.observers.length === 0) {
      this.dialogRef.close();
    } else {
      this.cancelClicked.emit();
    }
  }

  public onSaveClicked(): void {
    this.saveClicked.emit();
  }
}
