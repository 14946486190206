export const APP_ROUTES = {
  Clubhouse: 'home',
  Waiting: 'waiting',
  TainScroller: 'train-scroller',
  Stickers: 'stickers',
  Landing: 'landing',
  TeacherDashboard: 'teacher-dashboard',
  AdminPanel: 'admin-panel',
  Library: 'library',
  Madlibs: 'silly-stories',
  Player: 'player',
  Games: 'games',
  Preview: 'preview',
};
