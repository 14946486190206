import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Program,
  StorySet,
  flattenStorySet,
} from '@app/admin-panel/models/program';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, combineLatest, of, zip } from 'rxjs';
import { StudentData, StudentStoryCompletionData } from '../models';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { map, mergeMap, tap, filter, catchError } from 'rxjs/operators';
import { Story } from '../models/new-story';
import { Cohort } from '@app/teacher-dashboard/models/cohort';
import { CohortService } from '@app/teacher-dashboard/services/cohort.service';
import { flattenArrayReduce, inputIsNotNullOrUndefined } from '../helpers';
import { startOfDate } from '../date-utils';
import { Team, TeamsResponse } from '@app/player/models/team.model';
import { StorageService } from '../storage.service';
import { PRESET_STUDENT_AVATAR } from '../avatars.catalog';
import { LibraryStory } from '@app/library/library-facade.service';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  private _studentData = new BehaviorSubject<StudentData | undefined>(
    undefined
  );

  private _studentProgram = new BehaviorSubject<Program | undefined>(undefined);
  private _teamsResults = new BehaviorSubject<Team[]>([]);
  private _studentClass = new BehaviorSubject<Cohort | undefined>(undefined);
  private _studentAvatar = new BehaviorSubject<string>(PRESET_STUDENT_AVATAR);

  public get teamResults(): Team[] {
    return this._teamsResults.value;
  }

  public get studentAvatar(): string {
    return this._studentAvatar.value;
  }

  public get studentData(): StudentData | undefined {
    return this._studentData.value;
  }

  public get studentProgram(): Program | undefined {
    return this._studentProgram.value;
  }

  public get studentClass(): Cohort | undefined {
    return this._studentClass.value;
  }

  public studentAvatar$: Observable<string> =
    this._studentAvatar.asObservable();

  public teamsResults$: Observable<Team[]> = this._teamsResults.asObservable();

  /** Student profile data including completed stories, class & program data */
  public studentData$: Observable<StudentData> =
    this._studentData.asObservable();

  /** Returns current available story set
   * to display on the clubhouse page based on current date and user progress */
  public currentWeek$: Observable<StorySet> = combineLatest([
    this._studentData,
    this._studentProgram,
  ]).pipe(
    filter(inputIsNotNullOrUndefined),
    map(([data, program]) => this.getCurrentWeeksStorySet(data, program))
  );

  public studentClass$: Observable<Cohort> = this._studentClass.asObservable();

  public sideStoriesForCurrentWeekBlocked$: Observable<boolean> = combineLatest(
    [this.studentClass$, this.currentWeek$, this.studentData$]
  ).pipe(
    map(([cohort, storySet, studentData]) => {
      if (
        inputIsNotNullOrUndefined([cohort, storySet, studentData]) &&
        cohort.completeMainStoryRequired &&
        storySet.mainStory
      ) {
        return !isStoryCompleted(storySet.mainStory, studentData);
      }
      return false;
    })
  );

  public get dataLoaded(): boolean {
    return inputIsNotNullOrUndefined([
      this.studentClass,
      this.studentData,
      this.studentProgram,
    ]);
  }

  public gamesUnlocked$: Observable<boolean> = combineLatest([
    this.studentClass$,
    this.studentData$,
  ]).pipe(
    filter(inputIsNotNullOrUndefined),
    map(([cohort, studentData]) => {
      const readStories = studentData.stories.filter((s) => s.readCount > 0);
      return readStories.length >= cohort.requireStoriesCompleteToUnlockGames;
    })
  );

  constructor(
    private http: HttpClient,
    private credsService: CredentialsService,
    private authService: AuthenticationService,
    private cohortsService: CohortService,
    private storageService: StorageService
  ) {
    this.authService.onLogout.subscribe(() => {
      this.reset();
    });
  }

  public fetchStudentsLibrary(): Observable<LibraryStory[]> {
    return combineLatest([
      this._fetchStudentsLibrary(),
      this.currentWeek$,
      this.sideStoriesForCurrentWeekBlocked$,
    ]).pipe(
      map(([stories, currentWeek, sideStoriesBlocked]) => {
        return stories.map((story) => {
          return {
            ...story,
            disabled:
              story.type === 'SIDE' &&
              sideStoriesBlocked &&
              storySetIncludes(story.id, currentWeek),
          };
        });
      })
    );
  }

  private _fetchStudentsLibrary(): Observable<LibraryStory[]> {
    return this.refreshStudentData().pipe(
      map(() =>
        this.unblockedStorySets(
          this._studentData.value,
          this._studentProgram.value
        )
      ),
      map((storySets) => storySets.map(flattenStorySet)),
      map((stories) => flattenArrayReduce(stories))
    );
  }

  public refreshStudentData(): Observable<{
    studentData: StudentData;
    program: Program;
    class: Cohort;
  }> {
    return this.fetchStudentProfile().pipe(
      mergeMap((data) =>
        zip(
          this.fetchStudentProgram(data.programId),
          this.fetchStudentClass(data.classId)
        )
      ),
      map(() => {
        return {
          studentData: this.studentData,
          program: this.studentProgram,
          class: this.studentClass,
        };
      })
    );
  }

  public studentProgressForStory(
    storyId: string
  ): StudentStoryCompletionData | undefined {
    const story = this._studentData.value?.stories?.find(
      (s) => s.storyId === storyId
    );
    return story;
  }

  public fetchClassTeams(classId: string): Observable<Team[]> {
    return this.http.get<TeamsResponse>(this.classTeamUrl(classId)).pipe(
      catchError(() => of({ teams: [] })),
      tap((response) => this._teamsResults.next(response.teams)),
      map((response) => response.teams)
    );
  }

  public fetchStudentClass(classId: string): Observable<Cohort> {
    return this.cohortsService
      .getClassById(classId)
      .pipe(tap((classData) => this._studentClass.next(classData)));
  }

  public fetchStudentProfile(): Observable<StudentData> {
    const studentDataUrl = `${environment.hubApi}/users/${this.credsService.credentials?.userId}/profile`;
    return this.http
      .get<StudentData>(studentDataUrl)
      .pipe(tap((studentData) => this._studentData.next(studentData)));
  }

  public fetchStudentProgram(id: string): Observable<Program> {
    return this.http
      .get<Program>(this.studentProgramUrl(id))
      .pipe(tap((program) => this._studentProgram.next(program)));
  }

  public setStudentAvatar(avatar: string): void {
    this.storageService.add('student-avatar', avatar);
    this._studentAvatar.next(avatar);
  }

  public fetchStudentAvatar(): void {
    const avatar = this.storageService.get('student-avatar')?.v;
    this._studentAvatar.next(avatar ?? PRESET_STUDENT_AVATAR);
  }

  private unblockedStorySets(
    studentData: StudentData | undefined,
    program: Program | undefined
  ): StorySet[] {
    const currentStorySet = this.getCurrentWeeksStorySet(studentData, program);

    if (!currentStorySet) {
      return [];
    }

    const availableStorySets = program.storySets.filter(
      (set) =>
        startOfDate(new Date(set.startDate)).getTime() <
        startOfDate(new Date(currentStorySet.startDate)).getTime()
    );
    return [...availableStorySets, currentStorySet];
  }

  private getCurrentWeeksStorySet(
    studentData: StudentData | undefined,
    program: Program | undefined
  ): StorySet | undefined {
    if (!studentData || !program) {
      return undefined;
    }

    const availableSetsToDate = storySetsAvailableToCurrentDate(program);

    const earliestUncompletedSet = availableSetsToDate.find(
      (set) => !isStorySetCompleted(set, studentData)
    );

    const lastAvailableSet =
      availableSetsToDate[availableSetsToDate.length - 1];

    return earliestUncompletedSet ?? lastAvailableSet;
  }

  private studentProgramUrl(programId: string): string {
    return `${environment.hubApi}/programs/${programId}`;
  }

  private classTeamUrl(classId: string): string {
    return `${environment.hubApi}/classes/${classId}/teams`;
  }

  private reset(): void {
    this._studentData.next(null);
    this._studentProgram.next(null);
    this._studentClass.next(null);
    this._teamsResults.next([]);
  }
}

function storySetIncludes(storyId: string, storySet: StorySet): boolean {
  return (
    storySet.sideStories.find((story) => story.id === storyId) !== undefined ||
    storySet.mainStory?.id === storyId
  );
}

function isStoryCompleted(story: Story, studentData: StudentData): boolean {
  const completedStoryIds = studentData.stories.map((story) => story.storyId);
  return completedStoryIds.includes(story.id);
}

function isStorySetCompleted(set: StorySet, studentData: StudentData): boolean {
  const completedStoryIds = studentData.stories.map((story) => story.storyId);

  if (set.mainStory) {
    return completedStoryIds.includes(set.mainStory.id);
  }
  return set.sideStories.every((sideStory) =>
    completedStoryIds.includes(sideStory.id)
  );
}

function storySetsAvailableToCurrentDate(program: Program): StorySet[] {
  return program.storySets
    .filter((set) => {
      const startOfDay = startOfDate(new Date(set.startDate));
      return startOfDay.getTime() <= new Date().getTime();
    })
    .sort((set1, set2) => {
      return (
        new Date(set1.startDate).getTime() - new Date(set2.startDate).getTime()
      );
    });
}
