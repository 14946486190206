// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.core-prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hubApi: 'https://core-qa-mosaic-codex-hub-api.achieve3000.com',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
  mock: false,
  ceresEnv: 'prod',
  loginUrl: 'https://my.mheducation.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
