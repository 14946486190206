<div
  [hidden]="!isLoading"
  class="text-xs-center"
  [ngStyle]="{
    background: hasBackdrop ? background : 'none'
  }"
>
  <i
    class="fa-spinner-third fa-spin"
    [ngClass]="[size, weight]"
    style="--fa-animation-duration: 0.6s"
  ></i>
  <span
    class="message"
    [ngStyle]="{
      'color': messageColor,
      'font-size': messageFontSize
    }"
  >
    {{ message }}
  </span>
</div>
