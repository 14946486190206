<div class="drop-down">
  <input
    #input
    type="text"
    class="form-control"
    maxlength="255"
    required
    [formControl]="inputControl"
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    (focus)="focused.emit()"
  />
</div>
<mat-autocomplete
  #auto="matAutocomplete"
  class="custom-autocomplete"
  [displayWith]="getTitle"
>
  <mat-option *ngIf="isSearching; else optionsTemplate" disabled="true">
    <span translate>Searching...</span>
  </mat-option>

  <ng-template #optionsTemplate>
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option.title }}
    </mat-option>
  </ng-template>

  <mat-option *ngIf="!isSearching && noResults" value="" disabled="true">
    <b>No results found</b>
  </mat-option>
</mat-autocomplete>
