<div>
  <button class="btn btn-icon light close-btn" mat-dialog-close>
    <i class="fa-regular fa-xmark"></i>
  </button>

  <div *ngIf="title" class="title">{{ title }}</div>

  <ng-content></ng-content>

  <div
    class="my-4"
    fxLayoutGap="20px"
    fxLayoutAlign="end center"
    *ngIf="showsCancelButton || showsSaveButton"
  >
    <button
      *ngIf="showsCancelButton"
      class="btn btn-text light"
      type="reset"
      appClickSound
      appWhooshSound
      [disabled]="cancelButtonDisabled"
      (click)="onCancelClicked()"
    >
      <span translate>{{ cancelButtonTitle }}</span>
    </button>

    <button
      *ngIf="showsSaveButton"
      [id]="saveButtonId ?? ''"
      class="btn btn-primary"
      type="submit"
      [disabled]="saveButtonDisabled"
      appClickSound
      appWhooshSound
      (click)="onSaveClicked()"
    >
      <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
      <span translate>{{ saveButtonTitle }}</span>
    </button>
  </div>
</div>
