import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { CredentialsService } from '../../auth/credentials.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.credentialsService.isAuthenticated) {
      return true;
    }

    const redirect = state.url;

    if (!state.url.includes('/login')) {
      this.router.navigate(['/login'], {
        queryParams: { redirect },
        replaceUrl: true,
      });
    }
    return false;
  }
}
