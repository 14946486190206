export function advanceByWeek(date: string | Date): Date {
  const d = new Date(date);
  d.setDate(d.getDate() + 7);
  return d;
}

export function isSameDayOrGreater(date1: string, date2: string): boolean {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);
  return d1.getTime() >= d2.getTime();
}

export function datesEqual(date1: Date, date2: Date): boolean {
  return date1.getTime() === date2.getTime();
}

export function isSameDay(date1: string | Date, date2: string | Date): boolean {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);
  return d1.getTime() === d2.getTime();
}

export function startOfDate(date: string | Date): Date {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
}

export function getFirstDayOfWeek(currentDate: Date = new Date()): Date {
  const date = new Date(currentDate);
  date.setHours(0, 0, 0, 0);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
}

export function formatMinutesToHoursAndMinutes(minutes: number): string {
  if (isNaN(minutes) || minutes < 0) {
    return '';
  }

  if (minutes === 0) {
    return '0';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours} h ${remainingMinutes} min`;
  } else if (hours > 0) {
    return `${hours} h`;
  } else if (remainingMinutes > 0) {
    return `${remainingMinutes} min`;
  } else {
    return '0 min';
  }
}
