import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionsComponent } from './instructions/instructions.component';
import { DirectivesModule } from '@app/@shared/directives/directives.module';

@NgModule({
  declarations: [InstructionsComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [InstructionsComponent],
})
export class InstructionsModalModule {}
