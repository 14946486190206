import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { navigateToExternalUrl } from '@app/@shared/helpers';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { environment } from '@env/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() public menuItems = [];
  @Input() public instItems = [];

  constructor(
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private router: Router
  ) {}

  public logout(): void {
    const isAdmin = this.credentialsService.isAdmin;

    this.authenticationService.logout().subscribe(() => {
      isAdmin
        ? this.router.navigate(['/login'])
        : navigateToExternalUrl(environment.loginUrl);
    });
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.email : null;
  }
}
