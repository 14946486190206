import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Logger } from '../logger.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/auth/authentication.service';
import { ToastService } from '../services/toast.service';
import { environment } from '@env/environment';
import { navigateToExternalUrl } from '../helpers';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private readonly exceptionsList = ['/events'];
  private defaultError: string = 'There was an error, please retry';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastService: ToastService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.errorHandler(error)));
  }

  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    log.error('Request error', response);
    if (response instanceof HttpErrorResponse) {
      const errorMessage = response.error.message ?? this.defaultError;
      if (!this.isInExceptionList(response.url)) {
        this.toastService.error(errorMessage);
      }

      const isHubRequest = response.url.includes(environment.hubApi);

      if (response.status === HttpStatusCode.Unauthorized) {
        if (isHubRequest) {
          this.authenticationService.clearUser();
          navigateToExternalUrl(environment.loginUrl);
        }
      }
    }
    throw response;
  }

  private isInExceptionList(url: string): boolean {
    const path = new URL(url).pathname;
    return this.exceptionsList.includes(path);
  }
}
