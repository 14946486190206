import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Cohort } from '../models/cohort';
import { StorageService } from '@app/@shared/storage.service';
import { distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';
import { CreateCohortComponent } from '../dialogs/new-class/create-cohort.component';
import { MatDialog } from '@angular/material/dialog';
import { ProgramsService } from '@app/admin-panel/services/programs.service';
import { Program } from '@app/admin-panel/models/program';

export interface CohortsResponse {
  data: Cohort[];
  hasNext: boolean;
  pageLimit: number;
  pageNumber: number;
  pageCount: number;
}

export interface CohortData {
  cohort: Cohort;
  mode: 'create' | 'update';
  existingCohorts: Cohort[];
}

export type CohortUpdateModel = Omit<
  Cohort,
  'creator' | 'participants' | 'program'
>;

@Injectable({
  providedIn: 'root',
})
export class CohortService {
  private sessionCohortId = new BehaviorSubject<string | null>(null);

  private _cohortProgram$: BehaviorSubject<Program | null> =
    new BehaviorSubject<Program | null>(null);

  private _cohorts: Cohort[] = [];

  public selectedCohort$: BehaviorSubject<Cohort | null> =
    new BehaviorSubject<Cohort | null>(null);

  public selectedCohortId$ = this.sessionCohortId.pipe(distinctUntilChanged());
  public cohortProgram$ = this._cohortProgram$.asObservable();

  private _selectedCohort: Cohort | null;
  private _disableStoring: boolean = false;

  get cohorts(): Cohort[] {
    return this._cohorts;
  }

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private programsService: ProgramsService
  ) {
    this.sessionCohortId.pipe(distinctUntilChanged()).subscribe((cohortId) => {
      this.selectedCohort$.next(
        this._cohorts.find((cohort) => cohort.id === cohortId)
      );
      if (cohortId) {
        this.reloadCohort(cohortId);
      }
    });

    this.selectedCohort$.subscribe((cohort) => {
      this._selectedCohort = cohort;
    });
  }

  public reloadCohort(cohortId: string): void {
    this.getDetailedClassInfo(cohortId)
      .pipe(
        map((cohort) => new Cohort(cohort)),
        mergeMap((cohort) => {
          return this.programsService.getProgram(cohort.program.id).pipe(
            tap((program) => this._cohortProgram$.next(program)),
            map((program) => {
              cohort.program.storySets = program.storySets;
              return cohort;
            })
          );
        })
      )
      .subscribe(this.updateCohortsList.bind(this));
  }

  public getClassesForCurrentUser(): Observable<CohortsResponse> {
    return this.http.get<CohortsResponse>(`${environment.hubApi}/classes`);
  }

  public getClassesForSelectedUser(
    cohortId: string
  ): Observable<CohortsResponse> {
    return this.http.get<CohortsResponse>(
      `${environment.hubApi}/admin/users/${cohortId}/classes`
    );
  }

  public createNewClass(data: CohortUpdateModel): Observable<Cohort> {
    return this.http.post<Cohort>(`${environment.hubApi}/classes`, data);
  }

  public getClassById(cohortId: string): Observable<Cohort> {
    return this.http.get<Cohort>(this.cohortUrl(cohortId));
  }

  public updateClassById(
    data: CohortUpdateModel,
    cohortId: string
  ): Observable<string> {
    return this.http.put<string>(this.cohortUrl(cohortId), data);
  }

  public deleteClassById(cohortId: string): Observable<string> {
    return this.http.delete<string>(this.cohortUrl(cohortId));
  }

  public getDetailedClassInfo(cohortId: string): Observable<Cohort> {
    return this.http.get<Cohort>(this.cohortParticipantsUrl(cohortId));
  }

  public addStudentsToClass(
    cohortId: string,
    uids: string[]
  ): Observable<string> {
    return this.http.post<string>(this.cohortParticipantsUrl(cohortId), {
      userIDs: uids,
    });
  }

  public deleteStudentsFromClass(
    cohortId: string,
    uids: string[]
  ): Observable<string> {
    return this.http.post<string>(
      `${this.cohortParticipantsUrl(cohortId)}/delete`,
      { userIDs: uids }
    );
  }

  public initCohorts(data: Cohort[], disableStoring: boolean = false): void {
    this._cohorts = data.map((cohort) => new Cohort(cohort));
    this._cohorts.forEach((cohort) => (cohort.participants = []));

    const savedCohortId = this.storage.get(
      'teacher-dashboard-session-cohort-id'
    )?.v;
    const cohort = this._cohorts.find((cohort) => cohort.id === savedCohortId);

    if (cohort) {
      this.sessionCohortId.next(savedCohortId);
    }

    this._disableStoring = disableStoring;

    if (!this.sessionCohortId || !this._selectedCohort) {
      this.setDefaultCohortSelection();
    }
  }

  public updateCohortsList(cohort: Cohort): void {
    const existingCohortIndex = this._cohorts.findIndex(
      (item) => item.id === cohort.id
    );

    if (
      (existingCohortIndex && existingCohortIndex !== -1) ||
      existingCohortIndex === 0
    ) {
      this._cohorts[existingCohortIndex] = cohort;
      if (this._selectedCohort.id === cohort.id) {
        this.selectedCohort$.next(cohort);
      }
      return;
    }
    this._cohorts.push(cohort);
  }

  public deleteCohort(id: string): void {
    const existingCohortIndex = this._cohorts.findIndex(
      (item) => item.id === id
    );
    this._cohorts.splice(existingCohortIndex, 1);
    const previousCohort = this._cohorts[existingCohortIndex - 1];
    if (previousCohort) {
      this.setSessionCohort(previousCohort.id);
    } else {
      this.setDefaultCohortSelection();
    }
  }

  public setSessionCohort(id: string | null): void {
    this.sessionCohortId.next(id);

    if (this._disableStoring) {
      return;
    }

    if (id) {
      this.storage.add('teacher-dashboard-session-cohort-id', id);
    } else {
      this.storage.remove('teacher-dashboard-session-cohort-id');
    }
  }

  public createCohortDialog(dialog: MatDialog): void {
    const dialogRef = dialog.open(CreateCohortComponent, {
      data: {
        existingCohorts: this.cohorts,
        cohort: new Cohort(),
        mode: 'create',
      },
      panelClass: 'form-dialog',
    });

    dialogRef.afterClosed().subscribe((cohort) => {
      if (cohort) {
        this.updateCohortsList(cohort);
        this.setSessionCohort(cohort.id);
      }
    });
  }

  private setDefaultCohortSelection(): void {
    const cohortId = this.cohorts.length ? this.cohorts[0].id : null;
    this.setSessionCohort(cohortId);
  }

  private cohortUrl(cohortId: string): string {
    return `${environment.hubApi}/classes/${cohortId}`;
  }

  private cohortParticipantsUrl(cohortId: string): string {
    return `${this.cohortUrl(cohortId)}/participants`;
  }
}
