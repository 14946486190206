import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickSoundDirective } from './click-sound.directive';
import { WhooshSoundDirective } from './whoosh-sound.directive';
import { TrimOnBlurDirective } from './trim-on-blur.directive';
import { ErrorInputDirective } from './error-input.directive';
import { PositionDirective } from './position.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ClickSoundDirective,
    WhooshSoundDirective,
    TrimOnBlurDirective,
    ErrorInputDirective,
    PositionDirective,
  ],
  exports: [
    ClickSoundDirective,
    WhooshSoundDirective,
    TrimOnBlurDirective,
    ErrorInputDirective,
    PositionDirective,
  ],
})
export class DirectivesModule {}
