import { Injectable, inject } from '@angular/core';
import { CodexEvent } from './eventing.models';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@app/@shared';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const log = new Logger('EventingService');

export interface EventModel {
  eventName: string;
  eventType: string;
  timestamp: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class EventingService {
  private http = inject(HttpClient);

  public emitWithCallback(event: CodexEvent): Observable<EventModel> {
    return this.sendEvent(event);
  }

  public emit(event: CodexEvent): void {
    this.sendEvent(event).subscribe();
  }

  private sendEvent(event: CodexEvent): Observable<EventModel> {
    const newEvent: EventModel = {
      eventName: event.name,
      eventType: event.type,
      data: event.data,
      timestamp: new Date().toISOString(),
    };
    log.debug('SENDING EVENT', newEvent);
    return this.http.post<EventModel>(environment.hubApi + '/events', newEvent);
  }
}
