import { Injectable } from '@angular/core';
import { CeresEnv } from './components/ceres-player/ceres-constants';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '@app/@shared/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CeresService {
  public env$ = new BehaviorSubject<CeresEnv>(environment.ceresEnv as CeresEnv);

  private _token: string | undefined = undefined;

  constructor(private storageService: StorageService) {}

  public init(): void {
    const data = this.storageService.get('ceres-dev-data')?.v;
    if (data) {
      this._token = data.token;
      this.env$.next(data.env);
    }
  }

  public get token(): string | undefined {
    return this._token;
  }

  public get ceresEnv(): CeresEnv {
    return this.env$.value;
  }

  public setupPlayer(
    env: CeresEnv = environment.ceresEnv as CeresEnv,
    token: string
  ): void {
    this._token = token;
    this.env$.next(env);
    this.updateStorage();
  }

  public reset(): void {
    this.env$.next(environment.ceresEnv as CeresEnv);
    this._token = undefined;
    this.storageService.remove('ceres-dev-data');
  }

  private updateStorage(): void {
    const ceresData = {
      env: this.ceresEnv,
      token: this._token,
    };
    this.storageService.add('ceres-dev-data', ceresData);
  }
}
