import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { Program, StorySet } from '../models/program';
import { ListResponse } from './feedback.service';
import { inputIsNotNullOrUndefined } from '@app/@shared/helpers';

export interface ParamsForGetPrograms {
  keyword?: string;
  limit?: number;
  page?: number;
  sort?: string;
  direction?: SortDirection;
}

export enum ProgramsURL {
  Admin,
  Teacher,
}

export const adminProgramsUrl = `${environment.hubApi}/admin/programs`;
export const programsUrl = `${environment.hubApi}/programs`;

@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  private _urlType: ProgramsURL;

  public set url(value: ProgramsURL) {
    this._urlType = value;
    switch (value) {
      case ProgramsURL.Admin:
        this.baseUrl = adminProgramsUrl;
        break;
      case ProgramsURL.Teacher:
        this.baseUrl = programsUrl;
    }
  }

  constructor(private http: HttpClient) {}

  private baseUrl: string = programsUrl;

  public getProgramsForCohort(
    params: ParamsForGetPrograms
  ): Observable<ListResponse<Program>> {
    return this.http.get<ListResponse<Program>>(programsUrl, {
      params: params as HttpParams,
    });
  }

  public getPrograms(
    params: ParamsForGetPrograms
  ): Observable<ListResponse<Program>> {
    return this.http.get<ListResponse<Program>>(adminProgramsUrl, {
      params: params as HttpParams,
    });
  }

  public createProgram(program: Partial<Program>): Observable<Program> {
    return this.http.post<Program>(this.baseUrl, program);
  }

  public getProgram(programId: string): Observable<Program> {
    if (programId === '' || !inputIsNotNullOrUndefined(programId)) {
      return throwError('Failed to fetch program');
    }
    return this.http.get<Program>(this.programWithIdUrl(programId));
  }

  public deleteProgram(programId: string): Observable<any> {
    return this.http.delete(this.programWithIdUrl(programId));
  }

  public updateProgramAvailability(
    program: Program,
    enabled: boolean
  ): Observable<Program> {
    const programMinmimum: Pick<Program, 'title' | 'enabled'> = {
      title: program.title,
      enabled: enabled,
    };
    return this.http.put<Program>(
      this.programWithIdUrl(program.id),
      programMinmimum
    );
  }

  public updateProgram(
    programId: string,
    program: Program
  ): Observable<Program> {
    // TODO: remove once we have one request format
    const programBody = {
      ...program,
    };
    if (this._urlType === ProgramsURL.Teacher) {
      programBody.storySets = programBody.storySets.map((set) =>
        this.convertStorySetModelForTeacher(set)
      );
    } else {
      programBody.storySets = programBody.storySets.map((set) =>
        this.convertStorySetModeForAdmin(set)
      );
    }
    return this.http.put<Program>(
      this.programWithIdUrl(programId),
      programBody
    );
  }

  private programWithIdUrl(programId: string): string {
    return `${this.baseUrl}/${programId}`;
  }

  private convertStorySetModelForTeacher(storySet: StorySet): any {
    return {
      startDate: storySet.startDate,
      mainStory: storySet.mainStory?.id,
      sideStories: storySet.sideStories.map((s) => s.id),
    };
  }

  private convertStorySetModeForAdmin(storySet: StorySet): any {
    return {
      startDate: storySet.startDate,
      mainStory: storySet.mainStory?.id
        ? {
            id: storySet.mainStory.id,
          }
        : null,
      sideStories: storySet.sideStories.map((s) => {
        return {
          id: s.id,
        };
      }),
    };
  }
}
