import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback/feedback.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { DirectivesModule } from '@app/@shared/directives/directives.module';
import { DialogWrapperComponent } from '@app/@shared/components/dialog-wrapper/dialog-wrapper.component';

@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSelectModule,
    DirectivesModule,
    DialogWrapperComponent,
  ],
  exports: [FeedbackComponent],
})
export class FeedbackModule {}
