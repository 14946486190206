<app-dialog-wrapper
  [title]="'Leave Feedback'"
  [saveButtonDisabled]="form.invalid || isSubmitting"
  (saveClicked)="onSubmitClicked()"
  [isLoading]="isSubmitting"
>
  <form
    class="input-form"
    [formGroup]="form"
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <div fxLayoutAlign="start center" fxFlexAlign="start" fxLayoutGap="15px">
      <button
        id="add-files"
        class="add-files btn btn-secondary"
        (click)="fileInput.click()"
      >
        <i class="fa-solid fa-paperclip"></i>
      </button>

      <mat-select
        class="form-field"
        formControlName="type"
        style="min-width: 200px"
      >
        <ng-container *ngFor="let type of types">
          <mat-option [value]="type" translate>
            {{ type | titlecase }}
          </mat-option>
        </ng-container>
      </mat-select>
    </div>

    <div>
      <textarea
        appTrimOnBlur
        [placeholder]="placeholder"
        formControlName="feedback"
        class="form-control"
        name="feedback"
        id="feedback"
        #feedbackInput
        [appErrorInput]="{
          required: 'Description is required',
          maxlength: 'Max length is ' + maxDescriptionLength
        }"
      ></textarea>
    </div>

    <div fxLayout="column" fxLayoutAlign="start start">
      <input
        formControlName="attachments"
        [multiple]="multiple"
        fxHide="true"
        [accept]="accept"
        type="file"
        class="file-input"
        (change)="onFilesSelected($event)"
        #fileInput
      />

      <div class="error-block" *ngIf="errorMessages.length">
        <span class="text-danger" translate>
          Some of the files could not be uploaded:
        </span>
        <ng-container *ngFor="let error of generalizedErrorMessages">
          <span class="error-msg text-danger">
            {{ messageForError(error) }}
          </span>
        </ng-container>
      </div>

      <div
        *ngIf="files"
        class="files-container"
        fxLayout="row wrap"
        fxLayoutAlign="start center"
      >
        <ng-container *ngFor="let file of files; let i = index">
          <div class="file-container m-2">
            <img
              [height]="imgPreviewWidth"
              class="thumbnail"
              [src]="file.objectURL"
              *ngIf="isImage(file)"
              [width]="imgPreviewWidth"
            />
            <button
              [disabled]="isSubmitting"
              class="close-btn"
              (click)="onFileRemoveClicked(i)"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</app-dialog-wrapper>
