import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Logger } from '@app/@shared';
import { CredentialsService } from '@app/auth';
import { FeedbackComponent } from '@app/feedback/feedback/feedback.component';
import { UserRole } from '@app/@shared/models/user-role';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const log = new Logger('Shell');

export interface shellMenuItem {
  path?: string;
  label: string;
  icon?: string;
  src: string;
  onClick?: any;
  disabled?: boolean;
  elementId?: string;
}

export type SideMenuItem =
  | 'StudentHome'
  | 'Games'
  | 'Library'
  | 'TeacherDashboard'
  | 'OZ'
  | 'TeacherHome'
  | 'Feedback';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  public menuHidden: boolean = false;
  public menuItems: shellMenuItem[] = [];
  public instItems: shellMenuItem[] = [];

  private defaultMenu: shellMenuItem[] = [];
  private defaultInst: shellMenuItem[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private credsService: CredentialsService,
    private dialog: MatDialog
  ) {
    this.defaultMenu = this.menuItemsForUserRole().map(
      this.menuItemForType.bind(this)
    );
  }

  public ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close all open modals
        this.modalService.dismissAll();
      }
    });
  }

  public onActivate(componentReference): void {
    log.debug('onActivate');

    if (this.activatedRoute.snapshot.routeConfig?.children[0]?.data) {
      const data = this.activatedRoute.snapshot.routeConfig.children[0].data;
      this.menuHidden = data['menuHidden'];
    } else {
      this.menuHidden = false;
    }

    if (componentReference.menuItems) {
      componentReference.menuItems.subscribe((data) => {
        log.debug('menuItems', data);
        this.menuItems = data;
      });
    } else {
      this.menuItems = this.defaultMenu;
    }

    if (componentReference.instItems) {
      componentReference.instItems.subscribe((data) => {
        log.debug('instItems', data);
        this.instItems = data;
      });
    } else {
      this.instItems = this.defaultInst;
    }
  }

  private menuItemsForUserRole(): SideMenuItem[] {
    switch (this.credsService.credentials.role) {
      case UserRole.ADMIN:
        return ['TeacherDashboard', 'OZ', 'Games', 'Library', 'Feedback'];
      case UserRole.TEACHER:
        return ['TeacherHome', 'Games', 'Library', 'Feedback'];
      case UserRole.STUDENT:
        return ['StudentHome', 'Games', 'Library'];
    }
  }

  private menuItemForType(type: SideMenuItem): shellMenuItem {
    switch (type) {
      case 'TeacherHome':
        return {
          path: 'teacher-dashboard',
          label: 'Home',
          icon: 'fa-home',
          src: 'assets/icons/house-chimney.svg',
          elementId: 'sidebar-teacher-home',
        };
      case 'StudentHome':
        return {
          path: 'home',
          label: 'Home',
          icon: 'fa-home',
          src: 'assets/icons/house-chimney.svg',
          elementId: 'sidebar-student-home',
        };
      case 'Games':
        return {
          path: 'games',
          label: 'Games',
          icon: 'fa-gamepad-modern',
          src: 'assets/icons/gamepad-modern.svg',
          elementId: 'sidebar-games',
        };
      case 'Library':
        return {
          path: 'library',
          label: 'Library',
          icon: 'fa-books',
          src: 'assets/icons/books.svg',
          elementId: 'sidebar-library',
        };
      case 'TeacherDashboard':
        return {
          path: 'teacher-dashboard',
          label: 'Teacher Dashboard',
          icon: 'fa-chalkboard-user',
          src: 'assets/icons/teacher-dashboard.svg',
          elementId: 'sidebar-teacher-dashboard',
        };
      case 'OZ':
        return {
          path: 'admin-panel',
          label: 'OZ',
          icon: 'fa-chalkboard-user',
          src: 'assets/icons/teacher-dashboard.svg',
          elementId: 'sidebar-admin-panel',
        };
      case 'Feedback':
        return {
          label: 'Leave Feedback',
          src: 'assets/icons/feedback.svg',
          onClick: this.openFeedback.bind(this),
          elementId: 'sidebar-feedback',
        };
    }
  }

  private openFeedback(): void {
    this.dialog.open(FeedbackComponent, {
      panelClass: ['popup-dialog'],
    });
  }
}
