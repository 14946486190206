import { StudentData } from '@app/@shared/models';

export class playerData {
  public static data: StudentData[] = [
    {
      userId: '1c5925a5-e27d-41c1-a041-736a3b5ceedf',
      currentWeek: 1,
      classId: '0000001',
      programId: '000000A',
      firstName: 'Test',
      lastName: 'Student',
      stories: [
        {
          storyId: 'f21abc20-e57d-4741-b54e-390667850f6f',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: 'bf57c195-7cd8-48f6-9d2c-0a2a34fac8f5',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: 'd5df1413-58d6-4938-995f-55b8ddd0c3ce',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: '41a0d517-7d63-4cfd-9a98-e190cd672a00',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: '420c4018-01a8-4780-bbb0-1154bf9a3215',
          lastCompletion: null,
          readCount: 0,
        },
      ],
      skillProficiencies: [
        {
          skillId: '123456',
          proficiencyLevel: 1,
          lastUpdated: null,
        },
        {
          skillId: '123457',
          proficiencyLevel: 1,
          lastUpdated: null,
        },
        {
          skillId: '123458',
          proficiencyLevel: 1,
          lastUpdated: null,
        },
      ],
      stickersAcquired: [
        {
          stickerName: "What's for Lunch?",
          stickerUri:
            'https://d1svz6cez3e2p4.cloudfront.net/Illustration/a3ad5fd4-ef04-4020-8133-b56caa7e6eb1/whatsforlunch_closure_hotspot_salmon_revealed.png',
          stickerCategories: ['tab-1'],
        },
        {
          stickerName: 'Lightbulb',
          stickerUri:
            'https://d1svz6cez3e2p4.cloudfront.net/Illustration/a5a89f50-007c-4bca-b3bf-69f52b02a285/thomasedison_closure_hotspot_lightbulb_revealed.png',
          stickerCategories: ['tab-1'],
        },
      ],
    },
    {
      userId: '1c5925a5-e27d-41c1-a041-736a3b5ceedf',
      currentWeek: 1,
      classId: '0000001',
      programId: '000000A',
      firstName: 'Test',
      lastName: 'Student',
      stories: [
        {
          storyId: 'bf57c195-7cd8-48f6-9d2c-0a2a34fac8f5',
          lastCompletion: '1661191264',
          readCount: 1,
        },
        {
          storyId: 'd5df1413-58d6-4938-995f-55b8ddd0c3ce',
          lastCompletion: '1661191264',
          readCount: 1,
        },
        {
          storyId: '41a0d517-7d63-4cfd-9a98-e190cd672a00',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: '420c4018-01a8-4780-bbb0-1154bf9a3215',
          lastCompletion: null,
          readCount: 0,
        },
      ],
      skillProficiencies: [
        {
          skillId: '123456',
          proficiencyLevel: 1,
          lastUpdated: null,
        },
        {
          skillId: '123457',
          proficiencyLevel: 2,
          lastUpdated: null,
        },
        {
          skillId: '123458',
          proficiencyLevel: 1,
          lastUpdated: null,
        },
      ],
      stickersAcquired: [
        {
          stickerName: "What's for Lunch?",
          stickerUri:
            'https://d1svz6cez3e2p4.cloudfront.net/Illustration/a3ad5fd4-ef04-4020-8133-b56caa7e6eb1/whatsforlunch_closure_hotspot_salmon_revealed.png',
          stickerCategories: ['tab-1'],
        },
        {
          stickerName: 'Lightbulb',
          stickerUri:
            'https://d1svz6cez3e2p4.cloudfront.net/Illustration/a5a89f50-007c-4bca-b3bf-69f52b02a285/thomasedison_closure_hotspot_lightbulb_revealed.png',
          stickerCategories: ['tab-1'],
        },
        {
          stickerName: 'Goggles',
          stickerUri:
            'https://d1svz6cez3e2p4.cloudfront.net/Illustration/56a0a471-2eba-4a5e-9490-2aac45cef5e7/eugenebullard_closure_hotspot_goggles_revealed.png',
          stickerCategories: ['tab-2'],
        },
      ],
    },
    {
      userId: '1c5925a5-e27d-41c1-a041-736a3b5ceedf',
      classId: '0000001',
      programId: '000000A',
      firstName: 'Test',
      currentWeek: 1,
      lastName: 'Student',
      stories: [
        {
          storyId: 'bf57c195-7cd8-48f6-9d2c-0a2a34fac8f5',
          lastCompletion: '1661191264',
          readCount: 1,
        },
        {
          storyId: 'd5df1413-58d6-4938-995f-55b8ddd0c3ce',
          lastCompletion: '1661191264',
          readCount: 1,
        },
        {
          storyId: '41a0d517-7d63-4cfd-9a98-e190cd672a00',
          lastCompletion: '1661191264',
          readCount: 1,
        },
        {
          storyId: '420c4018-01a8-4780-bbb0-1154bf9a3215',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: 'd0a182cd-8ae5-4beb-a31f-bf8f589d748d',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: '8ce98a4d-2630-4230-8ab1-a89e04f5c708',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: 'f5cf174e-cf4a-4242-b37f-73dd76c2b93e',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: '2e699bb7-7b4f-4e8a-8249-87f5d882f612',
          lastCompletion: null,
          readCount: 0,
        },
        {
          storyId: 'f60cc577-4531-4409-80e6-fe8956413081',
          lastCompletion: null,
          readCount: 0,
        },
      ],
      skillProficiencies: [
        {
          skillId: '123456',
          proficiencyLevel: 1,
          lastUpdated: null,
        },
        {
          skillId: '123457',
          proficiencyLevel: 2,
          lastUpdated: null,
        },
        {
          skillId: '123458',
          proficiencyLevel: 1,
          lastUpdated: null,
        },
      ],
      stickersAcquired: [
        {
          stickerName: "What's for Lunch?",
          stickerUri:
            'https://d1svz6cez3e2p4.cloudfront.net/Illustration/a3ad5fd4-ef04-4020-8133-b56caa7e6eb1/whatsforlunch_closure_hotspot_salmon_revealed.png',
          stickerCategories: ['tab-1'],
        },
        {
          stickerName: 'Lightbulb',
          stickerUri:
            'https://d1svz6cez3e2p4.cloudfront.net/Illustration/a5a89f50-007c-4bca-b3bf-69f52b02a285/thomasedison_closure_hotspot_lightbulb_revealed.png',
          stickerCategories: ['tab-1'],
        },
        {
          stickerName: 'Goggles',
          stickerUri:
            'https://d1svz6cez3e2p4.cloudfront.net/Illustration/56a0a471-2eba-4a5e-9490-2aac45cef5e7/eugenebullard_closure_hotspot_goggles_revealed.png',
          stickerCategories: ['tab-2'],
        },
      ],
    },
  ];
}
