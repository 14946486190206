import { Data } from '@app/games/madlibs/madlibs.model';

export class madlibsData {
  public static data: Data = {
    stories: [
      {
        id: 1,
        name: 'A Garden for Room 8',
        text: 'Last week, our teacher told the class about an exciting new project. We get to build our own community garden! Mari asked around to see what her friends planned to bring. She wrote down notes on a {n1}. Sofia wants to plant {pn1}. John is going to bring a {n2} from his pet {an1}. Finally, Mari decided she will plant {adj1} flowers.',
        reveal: {
          background:
            '/assets/images/madlibs/backgrounds/madlibs_bg_gardenforroom8.jpg',
          positions: [
            {
              id: 'n1',
              type: 'normal',
              combo: ['n1'],
              placement: 'normal',
              top: 37,
              left: 65,
              width: 7,
              case: 'lowerAll',
            },
            {
              id: 'pn1',
              type: 'normal',
              combo: ['pn1'],
              placement: 'normal',
              top: 60,
              left: 40,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'n2',
              type: 'normal',
              combo: ['n2'],
              placement: 'normal',
              top: 55,
              left: 25,
              width: 7,
              case: 'lowerAll',
            },
            {
              id: 'an1',
              type: 'normal',
              combo: ['an1'],
              placement: 'normal',
              top: 60,
              left: 65,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'adj1',
              type: 'normal',
              combo: ['adj1'],
              placement: 'normal',
              top: 60,
              left: 8,
              width: 7,
              case: 'lowerAll',
            },
          ],
          assets: [
            {
              word: 'orange',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_a_orange.png',
            },
            {
              word: 'shell',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_a_shell.png',
            },
            {
              word: 'box',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_a_box.png',
            },
            {
              word: 'shoe',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_a_shoe.png',
            },
            {
              word: 'string',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_a_string.png',
            },
            {
              word: 'dolls',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_b_dolls.png',
            },
            {
              word: 'bread',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_b_bread.png',
            },
            {
              word: 'globes',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_b_globe.png',
            },
            {
              word: 'books',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_b_books.png',
            },
            {
              word: 'mouse',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_d_mouse.png',
            },
            {
              word: 'kitten',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_d_kitten.png',
            },
            {
              word: 'hedgehog',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_d_hedgehog.png',
            },
            {
              word: 'bee',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_d_bee.png',
            },
            {
              word: 'huge',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_e_hugeflower.png',
            },
            {
              word: 'purple',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_e_purpleflower.png',
            },
            {
              word: 'tired',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_e_tiredflower.png',
            },
            {
              word: 'loud',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_e_loudflower.png',
            },
          ],
        },
        puzzleShapes: [
          {
            colorShape: 'green',
            slots: [
              {
                id: 'n1',
                name: 'Noun',
              },
              {
                id: 'n2',
                name: 'Noun',
              },
            ],
            words: ['Orange', 'Shell', 'Box', 'Shoe', 'String'],
          },
          {
            colorShape: 'purple',
            slots: [
              {
                id: 'pn1',
                name: 'Plural Noun',
              },
            ],
            words: ['Dolls', 'Bread', 'Globes', 'Books'],
          },
          {
            colorShape: 'blue',
            slots: [
              {
                id: 'an1',
                name: 'Animal',
              },
            ],
            words: ['Mouse', 'Kitten', 'Hedgehog', 'Bee'],
          },
          {
            colorShape: 'red',
            slots: [
              {
                id: 'adj1',
                name: 'Adjective',
              },
            ],
            words: ['Huge', 'Purple', 'Tired', 'Loud'],
          },
        ],
      },
      {
        id: 2,
        name: 'Dream House',
        text: "Last night, I was so tired that I fell asleep before my bedtime. I had a dream about a strange house. I've never seen anything like it before! The house was painted {c1}, and the roof was {c2}. Instead of a key, you unlocked the door with a {n1}. There were {pn1} in the windows that you could see from outside, and a large {n2} in the front yard. I'm not sure if it was my dream house or a nightmare house! What do you think?",
        reveal: {
          background:
            '/assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_bluehouse_blueroof.jpg',
          positions: [
            {
              id: 'c1',
              type: 'combined',
              combo: ['c1', 'c2'],
              placement: 'background',
              top: 50,
              left: 90,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'c2',
              type: 'combined',
              combo: ['c1', 'c2'],
              placement: 'background',
              top: 10,
              left: 10,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'n1',
              type: 'normal',
              combo: ['n1'],
              placement: 'normal',
              top: 47,
              left: 88,
              width: 8,
              case: 'lowerAll',
            },
            {
              id: 'pn1',
              type: 'normal',
              combo: ['pn1'],
              placement: 'normal',
              top: 30,
              left: 66,
              width: 12,
              case: 'lowerAll',
            },
            {
              id: 'n2',
              type: 'normal',
              combo: ['n2'],
              placement: 'normal',
              top: 50,
              left: 55,
              width: 30,
              case: 'lowerAll',
            },
          ],
          assets: [
            {
              word: 'blue,green',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_bluehouse_greenroof.jpg',
            },
            {
              word: 'blue,gray',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_bluehouse_greyroof.jpg',
            },
            {
              word: 'blue,orange',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_bluehouse_orangeroof.jpg',
            },
            {
              word: 'blue,pink',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_bluehouse_pinkroof.jpg',
            },
            {
              word: 'green,blue',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_greenhouse_blueroof.jpg',
            },
            {
              word: 'green,gray',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_greenhouse_greyroof.jpg',
            },
            {
              word: 'green,orange',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_greenhouse_orangeroof.jpg',
            },
            {
              word: 'green,pink',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_greenhouse_pinkroof.jpg',
            },
            {
              word: 'gray,blue',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_greyhouse_blueroof.jpg',
            },
            {
              word: 'gray,green',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_greyhouse_greenroof.jpg',
            },
            {
              word: 'gray,orange',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_greyhouse_orangeroof.jpg',
            },
            {
              word: 'gray,pink',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_greyhouse_pinkroof.jpg',
            },
            {
              word: 'orange,blue',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_orangehouse_blueroof.jpg',
            },
            {
              word: 'orange,green',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_orangehouse_greenroof.jpg',
            },
            {
              word: 'orange,gray',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_orangehouse_greyroof.jpg',
            },
            {
              word: 'orange,pink',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_orangehouse_pinkroof.jpg',
            },
            {
              word: 'pink,blue',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_pinkhouse_blueroof.jpg',
            },
            {
              word: 'pink,green',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_pinkhouse_greenroof.jpg',
            },
            {
              word: 'pink,gray',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_pinkhouse_greyroof.jpg',
            },
            {
              word: 'pink,orange',
              src: 'assets/images/madlibs/backgrounds/dreamhouse/madlibs_bg_dreamhouse_pinkhouse_orangeroof.jpg',
            },
            {
              word: 'shovel',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_shovel.png',
            },
            {
              word: 'donut',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_donut.png',
            },
            {
              word: 'fork',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_fork.png',
            },
            {
              word: 'pizzas',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_pizza1.png',
            },
            {
              word: 'baseballs',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_baseball.png',
            },
            {
              word: 'ladders',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_ladder1.png',
            },
            {
              word: 'book',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_book.png',
            },
            {
              word: 'volcano',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_e_volcano.png',
            },
            {
              word: 'painting',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_e_painting.png',
            },
          ],
        },
        puzzleShapes: [
          {
            colorShape: 'green',
            slots: [
              {
                id: 'c1',
                name: 'Color',
              },
              {
                id: 'c2',
                name: 'Color',
              },
            ],
            words: ['Blue', 'Green', 'Orange', 'Pink', 'Gray'],
          },
          {
            colorShape: 'purple',
            slots: [
              {
                id: 'n1',
                name: 'Noun',
              },
            ],
            words: ['Shovel', 'Donut', 'Fork'],
          },
          {
            colorShape: 'blue',
            slots: [
              {
                id: 'pn1',
                name: 'Plural Noun',
              },
            ],
            words: ['Pizzas', 'Baseballs', 'Ladders'],
          },
          {
            colorShape: 'red',
            slots: [
              {
                id: 'n2',
                name: 'Noun',
              },
            ],
            words: ['Book', 'Volcano', 'Painting'],
          },
        ],
      },
      {
        id: 3,
        name: 'Test Kitchen',
        text: "I came up with a recipe for a new dish! I haven't tested it yet, but we can try it out together. Here are the steps: \n1. Collect your ingredients. \n2. Mix together the {n1} with {lq1}. \n3. Chop a large pile of {veg1} and add it to the mix. \n4. Bake for {num1} minutes. \n5. Finally, sprinkle on a generous portion of {n2}. \n6. Enjoy...I hope!",
        reveal: {
          background:
            '/assets/images/madlibs/backgrounds/madlibs_bg_testkitchen.jpg',
          positions: [
            {
              id: 'n1',
              type: 'normal',
              combo: ['n1'],
              placement: 'normal',
              top: 15,
              left: 85,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'lq1',
              type: 'normal',
              combo: ['lq1'],
              placement: 'normal',
              top: 25,
              left: 67,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'veg1',
              type: 'normal',
              combo: ['veg1'],
              placement: 'normal',
              top: 47,
              left: 79,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'num1',
              type: 'normal',
              combo: ['num1'],
              placement: 'normal',
              top: 47,
              left: 62,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'n2',
              type: 'normal',
              combo: ['n2'],
              placement: 'normal',
              top: 69,
              left: 57,
              width: 10,
              case: 'lowerAll',
            },
          ],
          assets: [
            {
              word: 'paper',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_a_paper.png',
            },
            {
              word: 'mud',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_a_mud.png',
            },
            {
              word: 'phone',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_a_phone.png',
            },
            {
              word: 'orange',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_a_orange.png',
            },
            {
              word: 'tortilla',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_a_tortilla.png',
            },
            {
              word: 'tea',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_b_tea.png',
            },
            {
              word: 'lemon juice',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_b_lemon-juice.png',
            },
            {
              word: 'broth',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_b_broth.png',
            },
            {
              word: 'broccoli',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_c_broccoli.png',
            },
            {
              word: 'onions',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_c_onion.png',
            },
            {
              word: 'carrots',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_c_carrot.png',
            },
            {
              word: 'four',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_d_4.png',
            },
            {
              word: 'nineteen',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_d_19.png',
            },
            {
              word: 'two hundred',
              src: 'assets/images/madlibs/stickers/testkitchen/madlibs_stickers_testkitchen_d_200.png',
            },
          ],
        },
        puzzleShapes: [
          {
            colorShape: 'green',
            slots: [
              {
                id: 'n1',
                name: 'Noun',
              },
              {
                id: 'n2',
                name: 'Noun',
              },
            ],
            words: ['Paper', 'Mud', 'Phone', 'Orange', 'Tortilla'],
          },
          {
            colorShape: 'purple',
            slots: [
              {
                id: 'lq1',
                name: 'Liquid',
              },
            ],
            words: ['Tea', 'Lemon Juice', 'Broth'],
          },
          {
            colorShape: 'blue',
            slots: [
              {
                id: 'veg1',
                name: 'Vegetable',
              },
            ],
            words: ['Broccoli', 'Onions', 'Carrots'],
          },
          {
            colorShape: 'red',
            slots: [
              {
                id: 'num1',
                name: 'Number',
              },
            ],
            words: ['Four', 'Nineteen', 'Two Hundred'],
          },
        ],
      },
      {
        id: 4,
        name: 'Costume Contest',
        text: '{nm1} wanted to enter the silly costume <w role="amazon:NN">contest</w> this year. But they couldn\'t decide on just one theme! They had lots of hats, but decided to wear their favorite {role1} hat. Then, they put on some huge {adj1} glasses. Of course, they had to wear their lucky {an1} t-shirt! And they finished off the outfit with their favorite {n1} backpack! Just the right amount of silly to win the contest!',
        reveal: {
          background:
            '/assets/images/madlibs/backgrounds/madlibs_bg_costumecontest.jpg',
          positions: [
            {
              id: 'nm1',
              type: 'normal',
              combo: ['nm1'],
              placement: 'normal',
              top: 18,
              left: 55,
              width: 45,
              case: 'original',
            },
            {
              id: 'role1',
              type: 'normal',
              combo: ['role1'],
              placement: 'normal',
              top: 9.9,
              left: 70.8,
              width: 13,
              case: 'lowerAll',
              offsets: [
                { foreignId: 'nm1', name: 'Romana', x: 0, y: 0 },
                { foreignId: 'nm1', name: 'Rigo', x: 0, y: 0 },
              ],
            },
            {
              id: 'adj1',
              type: 'normal',
              combo: ['adj1'],
              placement: 'normal',
              top: 25,
              left: 72.4,
              width: 10,
              case: 'lowerAll',
              offsets: [
                { foreignId: 'nm1', name: 'Romana', x: 0.7, y: 3.3 },
                { foreignId: 'nm1', name: 'Rigo', x: -0.28, y: 3.7 },
              ],
            },
            {
              id: 'an1',
              type: 'normal',
              combo: ['an1'],
              placement: 'normal',
              top: 40,
              left: 71.5,
              width: 12,
              case: 'lowerAll',
              offsets: [
                { foreignId: 'nm1', name: 'Romana', x: 0, y: 4.3 },
                { foreignId: 'nm1', name: 'Rigo', x: -0.2, y: 4.7 },
              ],
            },
            {
              id: 'n1',
              type: 'normal',
              combo: ['n1'],
              placement: 'normal',
              top: 65,
              left: 54,
              width: 15,
              case: 'lowerAll',
            },
          ],
          assets: [
            {
              word: 'romana',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_a_romana.png',
            },
            {
              word: 'noemi',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_a_noemi.png',
            },
            {
              word: 'rigo',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_a_rigo.png',
            },
            {
              word: 'chef',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_b_chef-hat.png',
            },
            {
              word: 'firefighter',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_b_firefighter-hat.png',
            },
            {
              word: 'cowboy',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_b_cowboy-hat.png',
            },
            {
              word: 'blue',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_c_blue-glasses.png',
            },
            {
              word: 'dark',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_c_dark-glasses.png',
            },
            {
              word: 'flowery',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_c_flowery-glasses.png',
            },
            {
              word: 'puppy',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_d_dog-tshirt.png',
            },
            {
              word: 'giraffe',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_d_giraffe-tshirt.png',
            },
            {
              word: 'panda',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_d_panda-tshirt.png',
            },
            {
              word: 'phone',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_d_phone_backpack.png',
            },
            {
              word: 'pumpkin',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_d_pumpkin_backpack.png',
            },
            {
              word: 'pizza',
              src: 'assets/images/madlibs/stickers/costumecontest/madlibs_stickers_costumecontest_d_pizza_backpack.png',
            },
          ],
        },
        puzzleShapes: [
          {
            colorShape: 'green',
            slots: [
              {
                id: 'nm1',
                name: 'Name',
              },
            ],
            words: ['Romana', 'Noemi', 'Rigo'],
          },
          {
            colorShape: 'purple',
            slots: [
              {
                id: 'role1',
                name: 'Role/Job',
              },
            ],
            words: ['Chef', 'Firefighter', 'Cowboy'],
          },
          {
            colorShape: 'blue',
            slots: [
              {
                id: 'adj1',
                name: 'Adjective',
              },
            ],
            words: ['Blue', 'Dark', 'Flowery'],
          },
          {
            colorShape: 'red',
            slots: [
              {
                id: 'an1',
                name: 'Animal',
              },
            ],
            words: ['Puppy', 'Giraffe', 'Panda'],
          },
          {
            colorShape: 'magenta',
            slots: [
              {
                id: 'n1',
                name: 'Noun',
              },
            ],
            words: ['Phone', 'Pumpkin', 'Pizza'],
          },
        ],
      },
      {
        id: 5,
        name: 'Haunted House',
        text: "I got up in the middle of the night to get a drink of water. As I was walking down the stairs, I thought I heard a strange noise coming from the {rm1}. It sounded like a {adj1} buzz, but I told myself I was just imagining things. As I poured water into a glass, I heard it again! I screamed in fright, and it yelled back! “{ex1}” it yelled. Wait…the ghost can talk? I turned on the light to see my little brother munching on a piece of {fd1}. Guess I wasn't the only one taking midnight trips to the kitchen!",
        reveal: {
          background:
            '/assets/images/madlibs/backgrounds/madlibs_bg_huntedhouse.jpg',
          positions: [
            {
              id: 'rm1',
              type: 'normal',
              combo: ['rm1'],
              placement: 'normal',
              top: 48,
              left: 40,
              width: 20,
              case: 'lowerAll',
              offsets: [{ foreignId: 'rm1', name: 'Living Room', x: -2, y: 2 }],
            },
            {
              id: 'adj1',
              type: 'normal',
              combo: ['adj1'],
              placement: 'normal',
              top: 55,
              left: 2,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'ex1',
              type: 'normal',
              combo: ['ex1'],
              placement: 'normal',
              top: 13,
              left: 78,
              width: 10,
              case: 'original',
            },
            {
              id: 'fd1',
              type: 'normal',
              combo: ['fd1'],
              placement: 'normal',
              top: 33,
              left: 62,
              width: 10,
              case: 'lowerAll',
            },
          ],
          assets: [
            {
              word: 'bedroom',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_a_bedroom.png',
            },
            {
              word: 'kitchen',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_a_kitchen.png',
            },
            {
              word: 'living room',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_a_livingroom.png',
            },
            {
              word: 'angry',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_b_angry.png',
            },
            {
              word: 'tired',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_b_tired.png',
            },
            {
              word: 'fuzzy',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_b_fuzzy.png',
            },
            {
              word: 'gosh!',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_c_gosh.png',
            },
            {
              word: 'yowza!',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_c_yowza.png',
            },
            {
              word: 'kapow!',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_c_kapow.png',
            },
            {
              word: 'empanada',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_d_empanadas.png',
            },
            {
              word: 'sandwich',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_d_sandwich.png',
            },
            {
              word: 'salad',
              src: 'assets/images/madlibs/stickers/hauntedhouse/madlibs_stickers_huntedhouse_d_salad.png',
            },
          ],
        },
        puzzleShapes: [
          {
            colorShape: 'green',
            slots: [
              {
                id: 'rm1',
                name: 'Room',
              },
            ],
            words: ['Bedroom', 'Kitchen', 'Living Room'],
          },
          {
            colorShape: 'purple',
            slots: [
              {
                id: 'adj1',
                name: 'Adjective',
              },
            ],
            words: ['Angry', 'Tired', 'Fuzzy'],
          },
          {
            colorShape: 'blue',
            slots: [
              {
                id: 'ex1',
                name: 'Exclamation',
              },
            ],
            words: ['GOSH!', 'YOWZA!', 'KAPOW!'],
          },
          {
            colorShape: 'red',
            slots: [
              {
                id: 'fd1',
                name: 'Food',
              },
            ],
            words: ['Empanada', 'Sandwich', 'Salad'],
          },
        ],
      },
      {
        id: 6,
        name: 'Exercise Routine',
        text: "Kelly just put a new exercise routine together. First, she's going to carry a {noun1} above her head as she walks down the stairs. Next, she will do {number1} laps around the yard while singing {song1} as loudly as she can. Finally, she will {verb1} for at least five minutes before going back upstairs. Kelly is planning to do this routine three times a week!",
        reveal: {
          background:
            '/assets/images/madlibs/backgrounds/madlibs_bg_exerciseroutine.jpg',
          positions: [
            {
              id: 'noun1',
              type: 'normal',
              combo: ['noun1'],
              placement: 'normal',
              top: 0,
              left: 64,
              width: 22,
              case: 'lowerAll',
            },
            {
              id: 'number1',
              type: 'normal',
              combo: ['number1'],
              placement: 'normal',
              top: 50,
              left: 47,
              width: 15,
              case: 'lowerAll',
            },
            {
              id: 'song1',
              type: 'normal',
              combo: ['song1'],
              placement: 'normal',
              top: 43,
              left: 66,
              width: 7,
              case: 'original',
            },
            {
              id: 'verb1',
              type: 'normal',
              combo: ['verb1'],
              placement: 'normal',
              top: 59,
              left: 76,
              width: 22,
              case: 'lowerAll',
            },
          ],
          assets: [
            {
              word: 'pillow',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_a_pillow.png',
            },
            {
              word: 'puppy',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_a_puppy.png',
            },
            {
              word: 'guitar',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_a_guitar.png',
            },
            {
              word: 'seven',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_b_7.png',
            },
            {
              word: 'ten',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_b_10.png',
            },
            {
              word: 'one hundred',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_b_100.png',
            },
            {
              word: 'abcs',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_c_abc.png',
            },
            {
              word: 'baby shark',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_c_babyshark.png',
            },
            {
              word: 'happy birthday',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_c_happybirthday.png',
            },
            {
              word: 'dance',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_d_dance.png',
            },
            {
              word: 'laugh',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_d_laugh.png',
            },
            {
              word: 'skip',
              src: 'assets/images/madlibs/stickers/exerciseroutine/madlibs_stickers_exerciseroutine_d_skip.png',
            },
          ],
        },
        puzzleShapes: [
          {
            colorShape: 'green',
            slots: [
              {
                id: 'noun1',
                name: 'Noun',
              },
            ],
            words: ['Pillow', 'Puppy', 'Guitar'],
          },
          {
            colorShape: 'purple',
            slots: [
              {
                id: 'number1',
                name: 'Number',
              },
            ],
            words: ['Seven', 'Ten', 'One Hundred'],
          },
          {
            colorShape: 'blue',
            slots: [
              {
                id: 'song1',
                name: 'Song',
              },
            ],
            words: ['ABCs', 'Baby Shark', 'Happy Birthday'],
          },
          {
            colorShape: 'red',
            slots: [
              {
                id: 'verb1',
                name: 'Verb',
              },
            ],
            words: ['Dance', 'Laugh', 'Skip'],
          },
        ],
      },
      {
        id: 7,
        name: 'Weekend Adventure',
        text: 'Last weekend, my dad, brother, and I decided to go on an adventure. It turned out to be a {adj1} day. We took a {vehicle1}, but we got lost and ended up at the {place1}! But we made the most of it. We raced to see who could collect the most {pn1}. While we were there, I found a {n1} to bring back as a gift for my friend.',
        reveal: {
          background:
            '/assets/images/madlibs/backgrounds/weekendadventure/madlibs_bg_weekendadventure_rain.jpg',
          positions: [
            {
              id: 'adj1',
              type: 'normal',
              combo: ['adj1'],
              placement: 'normal',
              top: 0,
              left: 0,
              width: 100,
              case: 'lowerAll',
            },
            {
              id: 'vehicle1',
              type: 'normal',
              combo: [''],
              placement: 'normal',
              top: 0,
              left: 0,
              width: 100,
              case: 'lowerAll',
            },
            {
              id: 'place1',
              type: 'normal',
              combo: ['place1'],
              placement: 'normal',
              top: 0,
              left: 73,
              width: 14,
              case: 'lowerAll',
            },
            {
              id: 'pn1',
              type: 'normal',
              combo: ['pn1'],
              placement: 'normal',
              top: 40,
              left: 70,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'n1',
              type: 'normal',
              combo: ['n1'],
              placement: 'normal',
              top: 40,
              left: 20,
              width: 20,
              case: 'lowerAll',
            },
          ],
          assets: [
            {
              word: 'sunny',
              src: 'assets/images/madlibs/backgrounds/weekendadventure/madlibs_bg_weekendadventure_sunny.jpg',
            },
            {
              word: 'rainy',
              src: 'assets/images/madlibs/backgrounds/weekendadventure/madlibs_bg_weekendadventure_rain.jpg',
            },
            {
              word: 'windy',
              src: 'assets/images/madlibs/backgrounds/weekendadventure/madlibs_bg_weekendadventure_windy.jpg',
            },
            {
              word: 'snowy',
              src: 'assets/images/madlibs/backgrounds/weekendadventure/madlibs_bg_weekendadventure_snow.jpg',
            },
            {
              word: 'motorcycle',
              src: 'assets/images/madlibs/backgrounds/weekendadventure/madlibs_bg_weekendadventure_motorcycle.png',
            },
            {
              word: 'car',
              src: 'assets/images/madlibs/backgrounds/weekendadventure/madlibs_bg_weekendadventure_car.png',
            },
            {
              word: 'truck',
              src: 'assets/images/madlibs/backgrounds/weekendadventure/madlibs_bg_weekendadventure_truck.png',
            },
            {
              word: 'beach',
              src: 'assets/images/madlibs/stickers/weekendadventure/madlibs_stickers_weekendadventure_c_beach.png',
            },
            {
              word: 'woods',
              src: 'assets/images/madlibs/stickers/weekendadventure/madlibs_stickers_weekendadventure_c_wood.png',
            },
            {
              word: 'store',
              src: 'assets/images/madlibs/stickers/weekendadventure/madlibs_stickers_weekendadventure_c_store.png',
            },
            {
              word: 'shells',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_a_shell.png',
            },
            {
              word: 'boots',
              src: 'assets/images/stickerbook/stickers/plants/leaf.png',
            },
            {
              word: 'flowers',
              src: 'assets/images/madlibs/stickers/gardenforroom8/madlibs_stickers_gardenforroom8_e_hugeflower.png',
            },
            {
              word: 'book',
              src: 'assets/images/madlibs/stickers/dreamhouse/madlibs_stickers_dreamhouse_book.png',
            },
            {
              word: 'crayon',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_b_crayon.png',
            },
            {
              word: 'plant',
              src: 'assets/images/stickerbook/stickers/plants/leaf.png',
            },
          ],
        },
        puzzleShapes: [
          {
            colorShape: 'green',
            slots: [
              {
                id: 'adj1',
                name: 'Adjective',
              },
            ],
            words: ['Sunny', 'Rainy', 'Windy', 'Snowy'],
          },
          {
            colorShape: 'purple',
            slots: [
              {
                id: 'vehicle1',
                name: 'Vehicle',
              },
            ],
            words: ['Motorcycle', 'Car', 'Truck'],
          },
          {
            colorShape: 'blue',
            slots: [
              {
                id: 'place1',
                name: 'Place',
              },
            ],
            words: ['Beach', 'Woods', 'Store'],
          },
          {
            colorShape: 'red',
            slots: [
              {
                id: 'pn1',
                name: 'Plural Noun',
              },
            ],
            words: ['Shells', 'Boots', 'Flowers'],
          },
          {
            colorShape: 'magenta',
            slots: [
              {
                id: 'n1',
                name: 'Noun',
              },
            ],
            words: ['Book', 'Crayon', 'Plant'],
          },
        ],
      },
      {
        id: 8,
        name: 'A Strange Safari',
        text: "Let's go on a {place1} safari! When packing, you must be sure to bring a {noun1}. It is sure to be useful while you {verb1}. Don't forget to take a picture of the scenery. You might even see a {animal1} the size of your car! These animals are most likely to be seen {ving1}. Please don't feed them, or you might end up getting chased by a {animal2}!",
        reveal: {
          background:
            '/assets/images/madlibs/backgrounds/madlibs_bg_astrangesafari.jpg',
          positions: [
            {
              id: 'place1',
              type: 'normal',
              combo: ['place1'],
              placement: 'normal',
              top: 73,
              left: 73,
              width: 7,
              case: 'lowerAll',
            },
            {
              id: 'noun1',
              type: 'normal',
              combo: ['noun1'],
              placement: 'normal',
              top: 23,
              left: 54,
              width: 20,
              case: 'lowerAll',
            },
            {
              id: 'verb1',
              type: 'normal',
              combo: ['verb1'],
              placement: 'normal',
              top: 64,
              left: 52,
              width: 10,
              case: 'lowerAll',
            },
            {
              id: 'animal1',
              type: 'combined',
              combo: ['animal1', 'ving1'],
              placement: 'normal',
              top: 20,
              left: 0,
              width: 43,
              case: 'lowerAll',
            },
            {
              id: 'ving1',
              type: 'combined',
              combo: ['animal1', 'ving1'],
              placement: 'normal',
              top: 20,
              left: 0,
              width: 43,
              case: 'lowerAll',
            },
            {
              id: 'animal2',
              type: 'normal',
              combo: ['animal2'],
              placement: 'normal',
              top: 40,
              left: 82,
              width: 15,
              case: 'lowerAll',
            },
          ],
          assets: [
            {
              word: 'beach',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_a_beach.png',
            },
            {
              word: 'woods',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_a_wood.png',
            },
            {
              word: 'living room',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_a_livingroom.png',
            },
            {
              word: 'store',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_a_store.png',
            },
            {
              word: 'crayon',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_b_crayon.png',
            },
            {
              word: 'orange',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_b_orange.png',
            },
            {
              word: 'pillow',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_b_pillow.png',
            },
            {
              word: 'jump',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_c_jump.png',
            },
            {
              word: 'hide',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_c_hide.png',
            },
            {
              word: 'read',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_c_read.png',
            },
            {
              word: 'eat',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_c_eat.png',
            },
            {
              word: 'elephant,eating',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_eating-elephant.png',
            },
            {
              word: 'elephant,dancing',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_dancing-elephant.png',
            },
            {
              word: 'elephant,sleeping',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_sleeping-elephant.png',
            },
            {
              word: 'puppy,eating',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_eating-dog.png',
            },
            {
              word: 'puppy,dancing',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_dancing-dog.png',
            },
            {
              word: 'puppy,sleeping',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_sleeping-dog.png',
            },
            {
              word: 'giraffe,eating',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_eating-giraffe.png',
            },
            {
              word: 'giraffe,dancing',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_dancing-giraffe.png',
            },
            {
              word: 'giraffe,sleeping',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_sleeping-giraffe.png',
            },
            {
              word: 'cat,eating',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_eating-cat.png',
            },
            {
              word: 'cat,dancing',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_dancing-cat.png',
            },
            {
              word: 'cat,sleeping',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_sleeping-cat.png',
            },
            {
              word: 'elephant',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_dancing-elephant.png',
            },
            {
              word: 'puppy',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_dancing-dog.png',
            },
            {
              word: 'giraffe',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_dancing-giraffe.png',
            },
            {
              word: 'cat',
              src: 'assets/images/madlibs/stickers/astrangesafari/madlibs_stickers_astrangesafari_dancing-cat.png',
            },
          ],
        },
        puzzleShapes: [
          {
            colorShape: 'green',
            slots: [
              {
                id: 'place1',
                name: 'Place',
              },
            ],
            words: ['Beach', 'Woods', 'Living Room', 'Store'],
          },
          {
            colorShape: 'purple',
            slots: [
              {
                id: 'noun1',
                name: 'Noun',
              },
            ],
            words: ['Crayon', 'Orange', 'Pillow'],
          },
          {
            colorShape: 'blue',
            slots: [
              {
                id: 'verb1',
                name: 'Verb',
              },
            ],
            words: ['Jump', 'Hide', 'Read', 'Eat'],
          },
          {
            colorShape: 'red',
            slots: [
              {
                id: 'animal1',
                name: 'Animal',
              },
              {
                id: 'animal2',
                name: 'Animal',
              },
            ],
            words: ['Elephant', 'Cat', 'Puppy', 'Giraffe'],
          },
          {
            colorShape: 'magenta',
            slots: [
              {
                id: 'ving1',
                name: 'Verb ending in -ing',
              },
            ],
            words: ['Sleeping', 'Dancing', 'Eating'],
          },
        ],
      },
    ],
  };
}
