import { MatDialogRef } from '@angular/material/dialog';
import { Component, Input, Optional } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { formatBytes } from '@app/@shared/helpers';
import { ToastService } from '@app/@shared/services/toast.service';
import { FeedbackType } from '@app/admin-panel/models/feedback';
import { FeedbackService } from '@app/admin-panel/services/feedback.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  @Input() public accept: string = 'image/*';
  @Input() public filesLimit: number = 10;
  @Input() public maxFileSize: number = 10485760;
  @Input() public multiple: boolean = true;
  @Input() public imgPreviewWidth: number = 100;

  public readonly maxDescriptionLength: number = 5000;

  @Input() public placeholder: string =
    'We would love to hear your feedback! Please let us know about your experience with Cracking the Codex to help us improve';

  public readonly types = Object.values(FeedbackType);
  public form!: UntypedFormGroup;
  public isSubmitting = false;
  public errorMessages: UploadError[] = [];

  private _files: File[] = [];

  get files(): any[] {
    return this._files;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<FeedbackComponent>,
    private sanitizer: DomSanitizer,
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private feedbackService: FeedbackService
  ) {
    this.createForm();
  }

  public onFilesSelected(event): void {
    this.errorMessages = [];

    for (const file of event.target.files) {
      if (this._files.length === this.filesLimit) {
        this.errorMessages.push({
          error: 'filesLimitReached',
        });
        return;
      }

      if (this.validate(file)) {
        if (this.isImage(file)) {
          file.objectURL = this.sanitizer.bypassSecurityTrustUrl(
            window.URL.createObjectURL(file)
          );
        }
        this._files.push(file);
      }
    }
  }

  public onFileRemoveClicked(index: number): void {
    this._files.splice(index, 1);
  }

  public onSubmitClicked(): void {
    this.isSubmitting = true;
    this.feedbackService
      .createFeedback(
        this.files,
        this.form.controls['type'].value,
        this.form.controls['feedback'].value
      )
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe(
        () => {
          this.clearForm();
          this.toastService.show(
            'Your feedback has been submitted. Thank you!',
            {
              autohide: true,
            }
          );
          this.dialogRef.close();
        },
        (error) => {
          this.toastService.show(error.message, {
            autohide: true,
          });
        }
      );
  }

  private clearForm(): void {
    this._files = [];
    this.errorMessages = [];
    this.form.reset();
    this.createForm();
  }

  public isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  public messageForError(error: UploadError): string {
    if (error.error === 'filesLimitReached') {
      return `You can upload up to ${this.filesLimit} files`;
    } else if (error.error === 'invalidFileType') {
      return 'File type not allowed';
    } else if (error.error === 'maxSizeExceeded') {
      return `Max image size is ${formatBytes(this.maxFileSize, 0)}`;
    }
  }

  get generalizedErrorMessages(): UploadError[] {
    const result = this.errorMessages.reduce((acc, cur) => {
      if (!acc.includes(cur)) {
        acc.push(cur);
        return acc;
      }
    }, new Array<UploadError>());
    return result;
  }

  private validate(file: File): boolean {
    if (!this.isFileTypeValid(file)) {
      this.errorMessages.push({
        error: 'invalidFileType',
        fileName: file.name,
      });

      return false;
    }

    if (file.size > this.maxFileSize) {
      this.errorMessages.push({
        error: 'maxSizeExceeded',
        fileName: file.name,
      });
      return false;
    }
    return true;
  }

  private isFileTypeValid(file: File): boolean {
    const acceptableTypes = this.accept.split(',').map((type) => type.trim());
    return acceptableTypes.some((type) =>
      this.isItAcceptableFileType(file, type)
    );
  }

  private isItAcceptableFileType(file: File, type: string): boolean {
    return this.isWildcard(type)
      ? this.getTypeClass(file.type) === this.getTypeClass(type)
      : file.type == type ||
          this.getFileExtension(file).toLowerCase() === type.toLowerCase();
  }

  private isWildcard(fileType: string): boolean {
    return fileType.indexOf('*') !== -1;
  }

  private getTypeClass(fileType: string): string {
    return fileType.substring(0, fileType.indexOf('/'));
  }

  private getFileExtension(file: File): string {
    return '.' + file.name.split('.').pop();
  }

  private createForm(): void {
    this.form = this.fb.group({
      feedback: [
        '',
        [Validators.required, Validators.maxLength(this.maxDescriptionLength)],
      ],
      attachments: [],
      type: [FeedbackType.SUGGESTION, []],
    });
  }
}

export interface UploadError {
  error: 'maxSizeExceeded' | 'invalidFileType' | 'filesLimitReached';
  fileName?: string;
}
