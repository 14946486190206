import { arrayFromRange } from './helpers';

const userAvatars: number[] = arrayFromRange(1, 15);
const npcAvatars: number[] = arrayFromRange(1, 30);

export const PRESET_STUDENT_AVATAR: Readonly<string> =
  '/assets/images/avatars/1.svg';

export const DEFAULT_USER_AVATARS: Readonly<string[]> = userAvatars.map(
  (img) => `/assets/images/avatars/${img}.svg`
);

export const DEFAULT_NPC_AVATARS: Readonly<string[]> = npcAvatars.map(
  (img) => `/assets/images/NPC_avatars/${img}.png`
);
