import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AudioPlaybackService } from '@app/@shared/services/audio-playback.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss'],
})
export class InstructionsComponent {
  @Input() public title: string = '';
  @Input() public text: string[] = [];
  @Input() public confirmButtonTitle: string = 'Next';
  @Input() public audioModal: HTMLAudioElement | undefined = undefined;
  @Input() public playButtonVisible: boolean = true;

  @Output() public confirmClicked: EventEmitter<any> = new EventEmitter();

  constructor(public audioPlaybackService: AudioPlaybackService) {}

  public replayAudio(): void {
    this.audioPlaybackService.replayAudio(this.audioModal);
  }

  public onConfirm(): void {
    this.confirmClicked.emit();
  }
}
