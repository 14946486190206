import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appErrorInput]',
})
export class ErrorInputDirective implements OnInit {
  private hintElement: HTMLElement;

  @Input('appErrorInput') public errorMessages: { [string: string]: string };

  constructor(
    private el: ElementRef,
    private control: NgControl,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.control.statusChanges.subscribe(() => {
      const invalid = this.control.invalid && this.control.dirty;
      this.setClassAndErrorText(invalid);
    });
  }

  private setClassAndErrorText(invalid: boolean): void {
    const inputClassList = this.el.nativeElement.classList;

    if (invalid) {
      inputClassList.add('error');

      if (!this.hintElement) {
        this.createHint();
      }
    } else {
      inputClassList.remove('error');

      if (this.hintElement) {
        this.renderer.removeChild(
          this.el.nativeElement.parentNode,
          this.hintElement
        );
        this.hintElement = null;
      }
    }
  }

  private createHint(): void {
    this.hintElement = this.renderer.createElement('div');
    this.renderer.setStyle(this.hintElement, 'display', 'flex');
    this.renderer.setStyle(this.hintElement, 'flex-direction', 'row');
    this.renderer.setStyle(this.hintElement, 'column-gap', '5px');
    this.renderer.setStyle(this.hintElement, 'justify-content', 'flex-start');
    this.renderer.setStyle(this.hintElement, 'align-items', 'center');
    this.renderer.addClass(this.hintElement, 'hint');

    this.renderer.appendChild(this.hintElement, this.createIconElement());
    this.renderer.appendChild(this.hintElement, this.createErrorTextElement());

    if (this.el.nativeElement.parentNode.classList.contains('form-group')) {
      this.renderer.appendChild(
        this.el.nativeElement.parentNode.parentNode,
        this.hintElement
      );
    } else {
      this.renderer.appendChild(
        this.el.nativeElement.parentNode,
        this.hintElement
      );
    }
  }

  private getErrorMessages(): string[] {
    const errors: string[] = [];

    for (const error in this.errorMessages) {
      if (this.control.hasError(String(error))) {
        errors.push(this.errorMessages[error]);
      }
    }
    return errors.length ? errors : ['Field is not valid'];
  }

  private createErrorTextElement(): HTMLElement {
    const errorText = this.renderer.createElement('div');
    this.renderer.addClass(errorText, 'error-text');
    errorText.textContent = this.getErrorMessages().join(', ');
    return errorText;
  }

  private createIconElement(): HTMLElement {
    const iconElement = this.renderer.createElement('i');
    this.renderer.addClass(iconElement, 'fa-circle-exclamation');
    this.renderer.addClass(iconElement, 'fa-solid');
    this.renderer.addClass(iconElement, 'ic');
    this.renderer.setStyle(iconElement, 'color', '#e52335');
    this.renderer.setStyle(iconElement, 'width', '14px');
    this.renderer.setStyle(iconElement, 'height', '14px');
    return iconElement;
  }
}
