import { Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @HostBinding('class.ngb-toasts') public toastClass = true;

  constructor(public toastService: ToastService) {}

  public isTemplate(toast: any): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
