import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public title: string;
  public message: string;
  public yes: string;
  public no: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.dialogRef.addPanelClass('confirm-popup');
    this.title = data?.title;
    this.message = data?.message;
    this.yes = data?.yes ?? 'Yes';
    this.no = data?.no ?? 'No';
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export interface ConfirmDialogModel {
  message: string;
  yes: string;
  no: string;
  title: string;
}
