import { Injectable, TemplateRef } from '@angular/core';

export interface ToastOptions {
  animation?: boolean;
  autohide?: boolean;
  delay?: number;
  headerText?: string;
  classname?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public toasts: any[] = [];

  // Push new Toasts to array with content and options
  public show(
    textOrTpl: string | TemplateRef<any>,
    options: ToastOptions = {}
  ): void {
    this.toasts.push({ textOrTpl, ...options });
  }

  public error(textOrTpl: string | TemplateRef<any>): void {
    this.show(textOrTpl, {
      autohide: true,
      classname: 'error',
    });
  }

  // Callback method to remove Toast DOM element from view
  public remove(toast: any): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
