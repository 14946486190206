import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  public static notOneOf(forbiddenValues: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const isValid = !forbiddenValues.includes(value);

      return !isValid ? { forbiddenValue: true } : null;
    };
  }
}
