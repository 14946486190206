import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EventData } from './event.class';

/**
 * Usage:
 * Emit from component A:
 * `this.eventBusService.emit(new EventData('logout', null));`
 * Listen in component B:
 * `this.eventBusSubscription = this.eventBusService.on('logout', () => { this.logout() });`
 * Remember to unsubscribe `eventBusSubscription` in ngOnDestroy
 */

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private subject$ = new Subject<EventData>();

  public emit(event: EventData): void {
    this.subject$.next(event);
  }

  public eventReceived(eventName: string): Observable<EventData> {
    return this.subject$.pipe(filter((e: EventData) => e.name === eventName));
  }

  public on(eventName: string, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: EventData) => e.name === eventName),
        map((e: EventData) => e['value'])
      )
      .subscribe(action);
  }
}

export enum EventBusNames {
  game = 'game',
  story = 'story',
}
