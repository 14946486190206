import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { I18nService } from '@app/i18n';
import { MatIconRegistry } from '@angular/material/icon';
import { AnalyticsService } from './@shared/services/analytics.service';
import { UserActivityService } from './@shared/services/user-activity.service';
import { CeresService } from './ceres/ceres.service';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private analyticsService: AnalyticsService,
    private userActivityService: UserActivityService,
    private ceresService: CeresService
  ) {
    this.registerSvg();
  }

  public ngOnInit(): void {
    this.analyticsService.initialize();
    this.userActivityService.initialize();
    this.ceresService.init();
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );

    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        untilDestroyed(this)
      )
      .subscribe((route: ActivatedRoute) => {
        log.debug('onNavigationEnd', route.snapshot.routeConfig.path);
        const title =
          route.snapshot.data['title'] || route.parent.snapshot.data['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  public ngOnDestroy(): void {
    this.i18nService.destroy();
  }

  private registerSvg(): void {
    const icons = [
      'delete',
      'edit',
      'eye_closed',
      'eye_opened',
      'external',
      'calendar',
      'search',
      'triangle_down',
      'image_placeholder',
      'arrow_up',
      'arrow_down',
      'refresh',
      'info',
    ];

    const clubhouseImages = [
      'books',
      'games',
      'dashboard',
      'stickers',
      'trophies-shelf',
    ];

    const playerImages = ['avatar'];

    icons.forEach((i) => this.register('assets/icons/dashboards/', i));
    clubhouseImages.forEach((i) =>
      this.register('assets/images/clubhouse/', i)
    );
    playerImages.forEach((i) => this.register('assets/images/player/', i));
  }

  private register(route: string, title: string): void {
    this.matIconRegistry.addSvgIcon(
      title,
      this.domSanitizer.bypassSecurityTrustResourceUrl(route + title + '.svg')
    );
  }
}
