import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { Howl, Howler, HowlOptions } from 'howler';

@Injectable({
  providedIn: 'root',
})
export class SoundsService {
  private howler = Howler;
  private sounds: Map<string, Howl> = new Map<string, Howl>();

  set volume(value: number) {
    this.howler.volume(value);
  }

  get volume(): number {
    return this.howler.volume();
  }

  public register = (
    id: any,
    options: {
      src: string;
      autoplay?: boolean;
      volume?: number;
      loop?: boolean;
      html5?: boolean;
      preload?: boolean;
      onLoad?: () => void;
    }
  ): void => {
    const sound = new Howl({
      src: options.src,
      autoplay: options.autoplay ?? false,
      volume: options.volume ?? 1.0,
      loop: options.loop ?? false,
      html5: options.html5 ?? false,
      preload: options.preload ?? true,
    });
    if (options.onLoad) {
      sound.once('load', options.onLoad);
    }
    this.sounds.set(id, sound);
  };

  public unregister(id: string): boolean {
    if (!this.sounds.has(id)) {
      return true;
    }
    this.sounds.get(id).off();
    return this.sounds.delete(id);
  }

  public get(id: string): Howl {
    return this.sounds.get(id);
  }

  public mute(value: boolean = true): void {
    this.howler.mute(value);
  }

  public howl(options: HowlOptions): Howl {
    return new Howl(options);
  }

  public play(id: string): Observable<unknown> {
    const sound = this.sounds.get(id);
    return fromEvent(sound, 'end');
  }
}
