import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { AuthenticationService } from './authentication.service';
import { AudioService } from '@app/@shared/services/audio.service';
import { Subscription, Observable } from 'rxjs';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public error: string | undefined;
  public loginForm!: UntypedFormGroup;
  public isLoading: boolean = false;
  public isOLlogin: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private audioService: AudioService
  ) {
    this.createForm();
  }

  public ngOnInit(): void {
    const userId = this.route.snapshot.queryParams['userId'];
    const token = this.route.snapshot.queryParams['token'];
    if (userId !== undefined && token !== undefined) {
      this.isOLlogin = true;
      this.isLoading = true;
      this.loginWithToken(userId, token);
    }
  }

  public loginViaForm(): void {
    this.isLoading = true;
    this.audioService.init(); // fixes iOS audio issue

    const request = this.authenticationService.loginWithCredentials(
      this.loginForm.value
    );
    this.loginWithRequest(request);
  }

  private loginWithToken(userId: string, token: string): void {
    const request = this.authenticationService.loginWithToken(userId, token);
    this.loginWithRequest(request);
  }

  private loginWithRequest(request: Observable<any>): Subscription {
    return request
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loginForm.markAsPristine();
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          log.debug('Login successful');
          this.router.navigate(
            [this.route.snapshot.queryParams['redirect'] || '/'],
            { replaceUrl: true }
          );
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
          this.isLoading = false;
        }
      );
  }

  private createForm(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }
}
