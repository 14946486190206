import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { Logger } from '../logger.service';

import { stickersData } from '@data/stickers.data';
import { chaptersData } from '@data/chapters.data';
import { madlibsData } from '@data/madlibs.data';
import { trainData } from '@data/train.data';
import { playerData } from '@data/player.data';
import { programData } from '@data/program.data';

const log = new Logger('MockApiInterceptor');
const endpoints = [
  {
    url: '/stickers',
    data: stickersData.data,
  },
  {
    url: '/chapters',
    data: chaptersData.data,
  },
  {
    url: '/madlibs',
    data: madlibsData.data,
  },
  {
    url: '/train',
    data: trainData.data,
  },
  {
    url: '/train/upsert',
    data: [],
  },
  {
    url: '/auth/login',
    data: {
      username: 'test',
      token: '123456',
    },
  },
  {
    url: '/token',
    data: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJpYXQiOjE2NjE3MjQ0MDYsImV4cCI6MTk3NzM0MzYwNywiYXVkIjoiIiwic3ViIjoiIiwiaWQiOiIxYzU5MjVhNS1lMjdkLTQxYzEtYTA0MS03MzZhM2I1Y2VlZGYifQ.Huw-tdabw-eblqfK9ZroCD6281g1jOdKxe8B5lIHZoU',
  },
  {
    url: '/profile',
    data: playerData.data[1],
  },
  {
    url: '/schedule',
    data: {
      programs: programData.data[0], // student profiles only work with cohort 1
    },
  },
];

@Injectable({
  providedIn: 'root',
})
export class MockApiInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(500))
      .pipe(dematerialize());

    function handleRoute(): Observable<any> {
      const segments = new URL(request.url).pathname.split('/');
      segments.pop() || segments.pop(); // Handle potential trailing slash
      const prev = segments.pop();
      for (const endpoint of endpoints) {
        if (
          request.url === `/api${endpoint.url}` ||
          (request.params.get('mock') && `/${prev}` === endpoint.url)
        ) {
          if (request.method === 'GET' || request.method === 'POST') {
            log.debug('Loading mock data:', request.url, endpoint.data);
            return ok(endpoint.data);
          } else {
            log.debug(`${request.method} to ${endpoint.url}`, request.body);
            return ok();
          }
        }
      }
      // pass through any requests not handled above
      return next.handle(request);
    }

    // helper functions

    function ok(body?: any): Observable<HttpResponse<any>> {
      return of(new HttpResponse({ status: 200, body }));
    }
  }
}

export const MockBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: MockApiInterceptor,
  multi: true,
};
