import { NgClass, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass],
})
export class LoaderComponent {
  @Input() public isLoading = false;
  @Input() public message: string | undefined;
  @Input() public background: string = 'rgba(0, 0, 0, 0.6)';
  @Input() public hasBackdrop: boolean = true;
  @Input() public size: string = 'fa-4x';
  @Input() public weight: string = 'fa-solid';
  @Input() public messageFontSize: string = '16px';
  @Input() public messageColor: string = '#FFFFFF';
}
