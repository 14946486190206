import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { CredentialsService } from '@app/auth';
import { UserRole } from '../models/user-role';

@Injectable({
  providedIn: 'root',
})
export class TeacherDashboardGuard implements CanActivate {
  constructor(
    private credsService: CredentialsService,
    private router: Router
  ) {}

  public canActivate(): boolean | UrlTree {
    const creds = this.credsService.credentials;
    const redirect = this.router.parseUrl(`/home`);

    if (creds) {
      return [UserRole.TEACHER, UserRole.ADMIN].includes(creds.role)
        ? true
        : redirect;
    }
    return redirect;
  }
}
