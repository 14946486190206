import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  RouteReusableStrategy,
  ErrorHandlerInterceptor,
  MockApiInterceptor,
  SharedModule,
} from '@shared';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@env/environment';
import { DirectivesModule } from './@shared/directives/directives.module';
import { AuthInterceptor } from './@shared/http/auth.interceptor';
import { LottieModule } from 'ngx-lottie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDialogConfig,
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { FeedbackModule } from './feedback/feedback.module';
import {
  IntegrationLauncherModule,
  Config,
} from '@achieve-3000/dle-integration-ngx';
import { Observable } from 'rxjs';
import { CeresEnv } from './ceres/components/ceres-player/ceres-constants';
import { CeresService } from './ceres/ceres.service';
import { map } from 'rxjs/operators';
import { UserActivityService } from './@shared/services/user-activity.service';

export function playerFactory(): any {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

export function mapEnvtoLauncherConfig(env: CeresEnv): Config {
  return { sdlcEnvironment: env } as Config;
}

export function configFactory(initService: CeresService): Observable<Config> {
  return initService.env$.pipe(map(mapEnvtoLauncherConfig));
}

@NgModule({
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    AuthModule,
    DirectivesModule,
    MatIconModule,
    MatDialogModule,
    FeedbackModule,
    IntegrationLauncherModule.withConfig({
      useFactory: configFactory,
      deps: [CeresService],
    }),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    environment.mock
      ? {
          provide: HTTP_INTERCEPTORS,
          useClass: MockApiInterceptor,
          multi: true,
        }
      : [],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        autoFocus: false,
        closeOnNavigation: true,
      } as MatDialogConfig,
    },
    {
      provide: UserActivityService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
