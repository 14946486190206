import { User } from '@app/@shared/models/user';
import { StorySet } from '@app/admin-panel/models/program';

export interface CohortCreator {
  email: string;
  firstName: string;
  lastName: string;
}

export interface CohortProgram {
  id: string;
  title: string;
  storySets?: StorySet[];
}

export class Cohort {
  public title: string;
  public grade: '3' | '4' | '5' = '3';
  public startDate?: string;
  public id: string;
  public participants: User[] = [];
  public completeMainStoryRequired = true;
  public requireStoriesCompleteToUnlockGames = 3;
  public programId?: string;
  public creator: CohortCreator | undefined = undefined;
  public program: CohortProgram | undefined = undefined;

  constructor(cohort?: Partial<Cohort>) {
    if (cohort) {
      this.title = cohort.title;
      this.id = cohort.id;
      this.grade = cohort.grade || '3';
      this.startDate = cohort.startDate;
      this.completeMainStoryRequired = !!cohort.completeMainStoryRequired;
      this.requireStoriesCompleteToUnlockGames =
        cohort.requireStoriesCompleteToUnlockGames || 3;
      this.participants = cohort.participants || [];
      this.program = cohort.program;
      this.creator = cohort.creator;
    }
  }
}
