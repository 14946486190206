import { ChallengeDifficulty } from '@app/@shared/enums/challenge-difficulty';
import { StoryType } from '@app/@shared/models/story-type';

export type CodexEvent =
  | EventSelectDifficulty
  | EventChallengeAnswer
  | EventHeartBeat
  | EventSessionClose
  | EventSessionStart
  | EventStoryStarted
  | EventStoryClosed
  | EventGameStarted
  | EventGameClosed;

enum EventName {
  SELECT_DIFFICULTY = 'select_difficulty',
  CHALLENGE_ANSWSER = 'challenge_answer',
  SESSION_START = 'session_start',
  SESSION_CLOSE = 'session_close',
  HEARTBEAT = 'heartbeat',
  STORY_STARTED = 'story_started',
  STORY_CLOSED = 'story_closed',
  GAME_STARTED = 'game_started',
  GAME_CLOSED = 'game_closed',
}

enum EventType {
  SESSION = 'session',
  STORY = 'story',
  CHALLENGE = 'challenge',
  GAME = 'game',
  OTHER = 'other',
}

/** Emitted when game is considered started */
interface EventGameStarted {
  name: EventName.GAME_STARTED;
  type: EventType.GAME;
  data: EventGameStartedData;
}

export interface EventGameStartedData {
  game: string;
  class_id: string;
}

/** Emitted when game is either completed or closed */
interface EventGameClosed {
  name: EventName.GAME_CLOSED;
  type: EventType.GAME;
  data: EventGameClosedData;
}

export interface EventGameClosedData {
  game: string;
  game_completed: boolean;
  class_id: string;
}

/** Emitted when student selects dfifficulty on Ceres page with type = Difficulty_Selector */
interface EventSelectDifficulty {
  name: EventName.SELECT_DIFFICULTY;
  type: EventType.CHALLENGE;
  data: EventSelectDifficultyData;
}

export interface EventSelectDifficultyData {
  story_id: string;
  challenge_id: string;
  readthrough: number;
  difficulty: ChallengeDifficulty;
}

/** Emitted on each challenge answer attempt regardless of correctness */
interface EventChallengeAnswer {
  name: EventName.CHALLENGE_ANSWSER;
  type: EventType.CHALLENGE;
  data: EventChallengeAnswerData;
}

export interface EventChallengeAnswerData {
  story_id: string;
  challenge_id: string;
  readthrough: number;
  submitted_answers?: { [key: string]: string };
  attempt_count: number;
  skills: { [key: string]: number };
  response_time: number;
  evaluation: number;
  score: number;
  selected_difficulty: number;
  challenge_types: string[];
}

/** Emitted when story is started */
interface EventStoryStarted {
  name: EventName.STORY_STARTED;
  type: EventType.STORY;
  data: EventStoryStartedData;
}

interface EventStoryStartedData {
  story_id: string;
  readthrough: number;
  class_id: string;
  story_type: StoryType;
}

/** Emited when story is closed */
interface EventStoryClosed {
  name: EventName.STORY_CLOSED;
  type: EventType.STORY;
  data: EventStoryClosedData;
}

interface EventStoryClosedData {
  story_id: string;
  story_completed: boolean;
  score: number;
  readthrough: number;
  skipahead: boolean;
  class_id: string;
}

interface EventSessionStart {
  name: EventName.SESSION_START;
  type: EventType.SESSION;
  data: null;
}

interface EventSessionClose {
  name: EventName.SESSION_CLOSE;
  type: EventType.SESSION;
  data: null;
}

interface EventHeartBeat {
  name: EventName.HEARTBEAT;
  type: EventType.SESSION;
  data?: EventHeartBeatData;
}

interface EventHeartBeatData {
  class_id?: string;
  story_id?: string;
  game_id?: string;
}

export const eventsFactory = {
  HEARTBEAT: {
    create: (data: EventHeartBeatData | null = null): EventHeartBeat => {
      return { name: EventName.HEARTBEAT, type: EventType.SESSION, data };
    },
  },
  SESSION_CLOSE: {
    create: (): EventSessionClose => {
      return {
        name: EventName.SESSION_CLOSE,
        type: EventType.SESSION,
        data: null,
      };
    },
  },
  SESSION_START: {
    create: (): EventSessionStart => {
      return {
        name: EventName.SESSION_START,
        type: EventType.SESSION,
        data: null,
      };
    },
  },
  STORY_CLOSED: {
    create: (data: EventStoryClosedData): EventStoryClosed => {
      return { name: EventName.STORY_CLOSED, type: EventType.STORY, data };
    },
  },
  STORY_STARTED: {
    create: (data: EventStoryStartedData): EventStoryStarted => {
      return { name: EventName.STORY_STARTED, type: EventType.STORY, data };
    },
  },
  CHALLENGE_ANSWSER: {
    create: (data: EventChallengeAnswerData): EventChallengeAnswer => {
      return {
        name: EventName.CHALLENGE_ANSWSER,
        type: EventType.CHALLENGE,
        data,
      };
    },
  },
  SELECT_DIFFICULTY: {
    create: (data: EventSelectDifficultyData): EventSelectDifficulty => {
      return {
        name: EventName.SELECT_DIFFICULTY,
        type: EventType.CHALLENGE,
        data,
      };
    },
  },
  GAME_STARTED: {
    create: (data: EventGameStartedData): EventGameStarted => {
      return {
        name: EventName.GAME_STARTED,
        type: EventType.GAME,
        data,
      };
    },
  },
  GAME_CLOSED: {
    create: (data: EventGameClosedData): EventGameClosed => {
      return {
        name: EventName.GAME_CLOSED,
        type: EventType.GAME,
        data,
      };
    },
  },
};
