import { Directive, HostListener } from '@angular/core';
import { SoundsService } from '@app/@shared/services/sounds.service';

@Directive({
  selector: '[appWhooshSound]',
})
export class WhooshSoundDirective {
  constructor(private soundsService: SoundsService) {
    this.soundsService.register('whoosh', {
      src: '/assets/sounds/click-2-cleaned-smaller.mp3',
      volume: 0.1,
    });
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.soundsService.get('whoosh').play();
  }
}
