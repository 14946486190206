import { Directive, HostListener } from '@angular/core';
import { SoundsService } from '@app/@shared/services/sounds.service';

@Directive({
  selector: '[appClickSound]',
})
export class ClickSoundDirective {
  constructor(private soundsService: SoundsService) {
    this.soundsService.register('click', {
      src: '/assets/sounds/click-2-cleaned.mp3',
      volume: 0.5,
    });
    this.soundsService.register('clickHover', {
      src: '/assets/sounds/click-2-cleaned-smaller.mp3',
      volume: 0.3,
    });
    this.soundsService.register('clickBad', {
      src: '/assets/sounds/wrong-1.mp3',
      volume: 0.5,
    });
  }

  @HostListener('click')
  public onClick(): void {
    this.soundsService.get('click').play();
  }
  @HostListener('clickHover')
  public onHover(): void {
    this.soundsService.get('clickHover').play();
  }
  @HostListener('clickBad')
  public onClickBad(): void {
    this.soundsService.get('clickBad').play();
  }
}
