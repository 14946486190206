import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { FilesService } from '@app/@shared/services/files.service';
import { environment } from '@env/environment';
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, mergeMap } from 'rxjs/operators';
import { Feedback, FeedbackType } from '../models/feedback';

export interface ParamsForGetFeedback {
  keyword?: string;
  limit?: number;
  page?: number;
  sort?: string;
  status?: '' | 'ACTIVE' | 'ARCHIVED';
  direction?: SortDirection;
}

export interface ListResponse<Feedback> {
  data: Feedback[];
  hasNext: boolean;
  pageLimit: number;
  pageNumber: number;
  recordsCount: number;
}

export interface UpdateFeedbackData {
  read?: boolean;
  status?: string;
  adminNote?: string;
  important?: boolean;
}

export interface CreateFeedbackData {
  type: FeedbackType;
  feedbackText: string;
  attachments: string[];
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient, private filesService: FilesService) {}

  public getFeedback(
    params: ParamsForGetFeedback
  ): Observable<ListResponse<Feedback>> {
    return this.http.get<ListResponse<Feedback>>(
      `${environment.hubApi}/feedback`,
      { params: params as HttpParams }
    );
  }

  public deleteFeedback(id: string): Observable<any> {
    return this.http.delete(`${environment.hubApi}/feedback/${id}`);
  }

  public updateFeedback(
    id: string,
    data: UpdateFeedbackData
  ): Observable<Feedback> {
    return this.http.put<Feedback>(
      `${environment.hubApi}/feedback/${id}`,
      data
    );
  }

  public createFeedback(
    files: File[] = [],
    type: FeedbackType,
    text: string
  ): Observable<string> {
    return forkJoin(
      files.map((file) => this.filesService.uploadFile(file))
    ).pipe(
      defaultIfEmpty([]),
      mergeMap((result) => {
        const feedback: CreateFeedbackData = {
          attachments: result.map((r) => r.url),
          feedbackText: text,
          type: type,
        };
        return this._createFeedback(feedback);
      })
    );
  }

  private _createFeedback(data: CreateFeedbackData): Observable<string> {
    return this.http.post<string>(`${environment.hubApi}/feedback`, data);
  }
}
