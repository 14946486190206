<div class="modal-body">
  <button
    *ngIf="playButtonVisible"
    class="btn position-absolute shadow-none top-0 end-0 py-2 px-2 my-3 mx-3"
    (click)="replayAudio()"
    appClickSound
    appWhooshSound
  >
    <img
      src="/assets/icons/repeatPlay.png"
      style="width: 30px"
      alt="repeatPlay"
    />
  </button>
  <h1>{{ title }}</h1>
  <div class="text" *ngFor="let sentence of text" [innerHtml]="sentence"></div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary mx-5"
    (click)="onConfirm()"
    appClickSound
    appWhooshSound
  >
    {{ confirmButtonTitle }}
  </button>
</div>
