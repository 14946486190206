import { Injectable } from '@angular/core';
import { Credentials, CredentialsService } from '@app/auth';
import { Logger } from '../logger.service';
import { catchError, filter } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { DeepPartial } from '../helpers';
import { UserRole } from '../models/user-role';

declare let pendo: any;

const log = new Logger('AnalyticsService');

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private pendoMetaUrl: string = `${environment.hubApi}/organizations/meta`;

  constructor(
    private credsService: CredentialsService,
    private http: HttpClient
  ) {}

  public initialize(): void {
    this.credsService.isAuthenticated$
      .pipe(filter((isAuthenticated) => isAuthenticated))
      .subscribe(() => this.initPendo());
  }

  private initPendo(): void {
    const creds = this.credsService.credentials;

    if (!creds) {
      log.warn('Trying to initialize Pendo with missing credentials');
      return;
    }

    this.fetchPendoMetadata()
      .pipe(catchError(() => of(this.fallbackPendoMeta(creds))))
      .subscribe((meta) => pendo.initialize(meta));
  }

  private fetchPendoMetadata(): Observable<PendoMetadata> {
    return this.http.get<PendoMetadata>(this.pendoMetaUrl);
  }

  private fallbackPendoMeta(creds: Credentials): DeepPartial<PendoMetadata> {
    return {
      visitor: {
        id: creds.person_xid ?? 'cdx_' + creds.userId,
        mh_subject: 'Language Arts',
        mh_product: 'Cracking The Codex',
        mh_platform: 'OPEN_LEARNING',
        platform_role: 'codex_' + creds.role.toLowerCase(),
      },
    };
  }

  public track(event: AnalyticsEvent, trackForRoles: UserRole[] = []): void {
    const creds = this.credsService.credentials;

    if (trackForRoles.length > 0 && !trackForRoles.includes(creds?.role)) {
      return;
    }

    log.info('Logging pendo event', event);
    pendo.track(event.type, event.data);
  }
}

export enum AnalyticsEventType {
  ClassReportSorted = 'codex_classReportSorted',
  StoryPreview = 'codex_storyPreview',
  LaunchStory = 'codex_launchStory',
  ChallengeDiffSelected = 'codex_difficultySelected',
  ChallengeAnswerSubmitted = 'codex_challengeAnswerSubmitted',
  ClassParticipantsSearched = 'codex_classParticipantsSearched',
}

export interface AnalyticsEvent {
  type: AnalyticsEventType;
  data?: { [key: string]: string };
}

interface PendoMetadata {
  visitor: {
    id: string;
    codex_demo: boolean;
    codex_sr_user: boolean;
    mh_platform: string;
    mh_product: string;
    mh_subject: string;
    platform_role: string;
  };
  account: {
    id: string;
    name: string;
    mh_environment: string;
    mh_orgState: string;
    mh_rootOrgId: string;
    mh_rootOrgName: string;
  };
}
