<div class="sidebar d-flex flex-column flex-shrink-0 p-3">
  <ul class="nav nav-pills flex-column mb-auto">
    <li *ngFor="let item of menuItems">
      <a
        class="nav-link"
        [id]="item.elementId"
        [ngClass]="{ disabled: item.disabled }"
        [routerLink]="item.path"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="item.onClick ? item.onClick() : undefined"
        draggable="false"
        appClickSound
        appWhooshSound
      >
        <div>
          <img src="{{ item.src }}" draggable="false" />
        </div>
        <span translate>{{ item.label }}</span>
      </a>
    </li>
    <li *ngFor="let item of instItems">
      <a
        id="sidebar-instructions"
        class="nav-link"
        [routerLink]="item.path"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="item.onClick ? item.onClick() : undefined"
        draggable="false"
        appClickSound
        appWhooshSound
      >
        <div>
          <img src="{{ item.src }}" draggable="false" />
        </div>
        <span translate>{{ item.label }}</span>
      </a>
    </li>
  </ul>
  <!-- <button class="btn btn-text mb-5" [routerLink]="['player/auth']">
    Ceres
  </button> -->

  <div class="nav-footer d-flex justify-content-between">
    <button
      class="btn btn-text"
      (click)="logout()"
      translate
      appClickSound
      appWhooshSound
    >
      Logout
    </button>
  </div>
</div>
