export class programData {
  public static data = [
    [
      {
        programWeek: 1,
        storyId: 'f21abc20-e57d-4741-b54e-390667850f6f',
        storyTitle: 'Overall Assessment #1',
        storyType: 'Overall Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 1,
        storyId: 'bf57c195-7cd8-48f6-9d2c-0a2a34fac8f5',
        storyTitle: 'Guardians of the Librarium: Chapter 1',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/6ce4d373-9ddc-4174-99c7-3eff75a57ab4/CHAPTER01_00-COVER.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/6ce4d373-9ddc-4174-99c7-3eff75a57ab4/CHAPTER01_00-COVER.jpg',
        storyType: 'Main story',
      },
      {
        programWeek: 1,
        storyId: 'd5df1413-58d6-4938-995f-55b8ddd0c3ce',
        storyTitle: 'A Garden for Room 8',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/c0ab113a-ed3d-456a-931b-5d3547f6e409/gardenforroomeight_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/c0ab113a-ed3d-456a-931b-5d3547f6e409/gardenforroomeight_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 1,
        storyId: '41a0d517-7d63-4cfd-9a98-e190cd672a00',
        storyTitle: 'Thomas Edison',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b61ac61e-538d-48dd-a17f-2f541259c1ad/thomasedison_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b61ac61e-538d-48dd-a17f-2f541259c1ad/thomasedison_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 1,
        storyId: '420c4018-01a8-4780-bbb0-1154bf9a3215',
        storyTitle: 'Wilma Rudolph',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/e69362ee-73d5-4b1a-b96b-b011b9b829d8/WilmaRudolph_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/e69362ee-73d5-4b1a-b96b-b011b9b829d8/WilmaRudolph_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 2,
        storyId: 'd0a182cd-8ae5-4beb-a31f-bf8f589d748d',
        storyTitle: 'Weekly Check-in #1',
        storyType: 'Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 2,
        storyId: '8ce98a4d-2630-4230-8ab1-a89e04f5c708',
        storyTitle: 'Guardians of the Librarium: Chapter 2',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b6da24d2-3901-464e-a156-2b6963d0c758/ms2_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b6da24d2-3901-464e-a156-2b6963d0c758/ms2_cover.jpg',
        storyType: 'Main story',
      },
      {
        programWeek: 2,
        storyId: 'f5cf174e-cf4a-4242-b37f-73dd76c2b93e',
        storyTitle: 'A Project for Rigo',
        thumbnailUri: '',
        coverUri: '',
        storyType: 'Side quest',
      },
      {
        programWeek: 2,
        storyId: '2e699bb7-7b4f-4e8a-8249-87f5d882f612',
        storyTitle: 'Bass Reeves',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/183e5bc6-ea90-4c7c-98ca-06375a087756/bassreeves_cover%20(revised%20title).jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/183e5bc6-ea90-4c7c-98ca-06375a087756/bassreeves_cover%20(revised%20title).jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 2,
        storyId: 'f60cc577-4531-4409-80e6-fe8956413081',
        storyTitle: 'The Case of the Missing Meaning',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/c50a7624-cf45-4184-b5c0-0116928d2424/thecaseofthemissingmeaning_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/c50a7624-cf45-4184-b5c0-0116928d2424/thecaseofthemissingmeaning_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 3,
        storyId: 'd9d68ee0-d308-4de6-976e-cfe19ae49214',
        storyTitle: 'Weekly Check-in #2',
        storyType: 'Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 3,
        storyId: '1d31acb5-9900-41f9-b830-56de43ddcc1d',
        storyTitle: 'Guardians of the Librarium: Chapter 3',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/10e4db3c-6dac-455d-b0a9-a64b7841c15b/ms3_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/10e4db3c-6dac-455d-b0a9-a64b7841c15b/ms3_cover.jpg',
        storyType: 'Main story',
      },
      {
        programWeek: 3,
        storyId: '4c0c5dbe-f18b-4785-be82-d3e7c8014540',
        storyTitle: 'Lost Pet',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/4beb1b8a-22c8-46a9-b9c8-4f93b497ccef/lostpet_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/4beb1b8a-22c8-46a9-b9c8-4f93b497ccef/lostpet_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 3,
        storyId: '609fb0dc-b9e7-4f1b-be7a-37ad8bb689c9',
        storyTitle: 'Andre Talley',
        thumbnailUri: '',
        coverUri: '',
        storyType: 'Side quest',
      },
      {
        programWeek: 3,
        storyId: 'da329b0c-c45c-4899-ad6c-d1cd2862e7ef',
        storyTitle: 'Emily, Washington, and the Bridge',
        thumbnailUri: '',
        coverUri: '',
        storyType: 'Side quest',
      },
      {
        programWeek: 4,
        storyId: 'b2941770-1b6c-4b9c-b14c-70232deadd0d',
        storyTitle: 'Weekly Check-in #3',
        storyType: 'Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 4,
        storyId: '64158a3f-b9f3-4b2a-a02a-b5421c31a166',
        storyTitle: 'Guardians of the Librarium: Chapter 4',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b0adb2d5-ea1c-45f5-8f7a-917237d31965/CHAPTER04_00-COVER.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b0adb2d5-ea1c-45f5-8f7a-917237d31965/CHAPTER04_00-COVER.jpg',
        storyType: 'Main story',
      },
      {
        programWeek: 4,
        storyId: '07c92afe-f214-4891-8e89-ab23dbc58c30',
        storyTitle: 'The Case of the Borrowed History',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/2924ad8f-1145-49f9-9fa1-e8bcf6d224de/RML_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/2924ad8f-1145-49f9-9fa1-e8bcf6d224de/RML_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 4,
        storyId: 'eeda14be-bcd4-45b3-ade8-d8124db6b91f',
        storyTitle: 'Burmese Beauty in America',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/ab722b3c-12e4-4029-823a-48a790198e57/burmesebeautyinamerica_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/ab722b3c-12e4-4029-823a-48a790198e57/burmesebeautyinamerica_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 4,
        storyId: '1e494663-8649-48bc-ae12-19b02c5b364c',
        storyTitle: 'Helen Keller',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/58d5242d-9e70-4f03-98b3-2697cea2a796/helenkeller_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/58d5242d-9e70-4f03-98b3-2697cea2a796/helenkeller_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 5,
        storyId: '507b7100-193c-48a1-b2d1-fadfa8eb6d8d',
        storyTitle: 'Overall Assessment #2',
        storyType: 'Overall Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 9,
        storyId: '61a63578-95d0-4427-a198-b757b00f63c3',
        storyTitle: 'Overall Assessment #3',
        storyType: 'Overall Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
    ],
    [
      {
        programWeek: 1,
        storyId: 'f21abc20-e57d-4741-b54e-390667850f6f',
        storyTitle: 'Overall Assessment #1',
        storyType: 'Overall Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 5,
        storyId: '507b7100-193c-48a1-b2d1-fadfa8eb6d8d',
        storyTitle: 'Overall Assessment #2',
        storyType: 'Overall Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 5,
        storyId: 'bf57c195-7cd8-48f6-9d2c-0a2a34fac8f5',
        storyTitle: 'Guardians of the Librarium: Chapter 1',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/6ce4d373-9ddc-4174-99c7-3eff75a57ab4/CHAPTER01_00-COVER.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/6ce4d373-9ddc-4174-99c7-3eff75a57ab4/CHAPTER01_00-COVER.jpg',
        storyType: 'Main story',
      },
      {
        programWeek: 5,
        storyId: 'd5df1413-58d6-4938-995f-55b8ddd0c3ce',
        storyTitle: 'A Garden for Room 8',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/c0ab113a-ed3d-456a-931b-5d3547f6e409/gardenforroomeight_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/c0ab113a-ed3d-456a-931b-5d3547f6e409/gardenforroomeight_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 5,
        storyId: '41a0d517-7d63-4cfd-9a98-e190cd672a00',
        storyTitle: 'Thomas Edison',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b61ac61e-538d-48dd-a17f-2f541259c1ad/thomasedison_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b61ac61e-538d-48dd-a17f-2f541259c1ad/thomasedison_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 5,
        storyId: '420c4018-01a8-4780-bbb0-1154bf9a3215',
        storyTitle: 'Wilma Rudolph',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/e69362ee-73d5-4b1a-b96b-b011b9b829d8/WilmaRudolph_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/e69362ee-73d5-4b1a-b96b-b011b9b829d8/WilmaRudolph_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 6,
        storyId: 'd0a182cd-8ae5-4beb-a31f-bf8f589d748d',
        storyTitle: 'Weekly Check-in #1',
        storyType: 'Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 6,
        storyId: '8ce98a4d-2630-4230-8ab1-a89e04f5c708',
        storyTitle: 'Guardians of the Librarium: Chapter 2',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b6da24d2-3901-464e-a156-2b6963d0c758/ms2_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b6da24d2-3901-464e-a156-2b6963d0c758/ms2_cover.jpg',
        storyType: 'Main story',
      },
      {
        programWeek: 6,
        storyId: 'f5cf174e-cf4a-4242-b37f-73dd76c2b93e',
        storyTitle: 'A Project for Rigo',
        thumbnailUri: '',
        coverUri: '',
        storyType: 'Side quest',
      },
      {
        programWeek: 6,
        storyId: '2e699bb7-7b4f-4e8a-8249-87f5d882f612',
        storyTitle: 'Bass Reeves',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/183e5bc6-ea90-4c7c-98ca-06375a087756/bassreeves_cover%20(revised%20title).jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/183e5bc6-ea90-4c7c-98ca-06375a087756/bassreeves_cover%20(revised%20title).jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 6,
        storyId: 'f60cc577-4531-4409-80e6-fe8956413081',
        storyTitle: 'The Case of the Missing Meaning',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/c50a7624-cf45-4184-b5c0-0116928d2424/thecaseofthemissingmeaning_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/c50a7624-cf45-4184-b5c0-0116928d2424/thecaseofthemissingmeaning_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 7,
        storyId: 'd9d68ee0-d308-4de6-976e-cfe19ae49214',
        storyTitle: 'Weekly Check-in #2',
        storyType: 'Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 7,
        storyId: '1d31acb5-9900-41f9-b830-56de43ddcc1d',
        storyTitle: 'Guardians of the Librarium: Chapter 3',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/10e4db3c-6dac-455d-b0a9-a64b7841c15b/ms3_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/10e4db3c-6dac-455d-b0a9-a64b7841c15b/ms3_cover.jpg',
        storyType: 'Main story',
      },
      {
        programWeek: 7,
        storyId: '4c0c5dbe-f18b-4785-be82-d3e7c8014540',
        storyTitle: 'Lost Pet',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/4beb1b8a-22c8-46a9-b9c8-4f93b497ccef/lostpet_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/4beb1b8a-22c8-46a9-b9c8-4f93b497ccef/lostpet_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 7,
        storyId: '609fb0dc-b9e7-4f1b-be7a-37ad8bb689c9',
        storyTitle: 'Andre Talley',
        thumbnailUri: '',
        coverUri: '',
        storyType: 'Side quest',
      },
      {
        programWeek: 7,
        storyId: 'da329b0c-c45c-4899-ad6c-d1cd2862e7ef',
        storyTitle: 'Emily, Washington, and the Bridge',
        thumbnailUri: '',
        coverUri: '',
        storyType: 'Side quest',
      },
      {
        programWeek: 8,
        storyId: 'b2941770-1b6c-4b9c-b14c-70232deadd0d',
        storyTitle: 'Weekly Check-in #3',
        storyType: 'Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
      {
        programWeek: 8,
        storyId: '64158a3f-b9f3-4b2a-a02a-b5421c31a166',
        storyTitle: 'Guardians of the Librarium: Chapter 4',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b0adb2d5-ea1c-45f5-8f7a-917237d31965/CHAPTER04_00-COVER.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/b0adb2d5-ea1c-45f5-8f7a-917237d31965/CHAPTER04_00-COVER.jpg',
        storyType: 'Main story',
      },
      {
        programWeek: 8,
        storyId: '07c92afe-f214-4891-8e89-ab23dbc58c30',
        storyTitle: 'The Case of the Borrowed History',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/2924ad8f-1145-49f9-9fa1-e8bcf6d224de/RML_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/2924ad8f-1145-49f9-9fa1-e8bcf6d224de/RML_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 8,
        storyId: 'eeda14be-bcd4-45b3-ade8-d8124db6b91f',
        storyTitle: 'Burmese Beauty in America',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/ab722b3c-12e4-4029-823a-48a790198e57/burmesebeautyinamerica_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/ab722b3c-12e4-4029-823a-48a790198e57/burmesebeautyinamerica_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 8,
        storyId: '1e494663-8649-48bc-ae12-19b02c5b364c',
        storyTitle: 'Helen Keller',
        thumbnailUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/58d5242d-9e70-4f03-98b3-2697cea2a796/helenkeller_cover.jpg',
        coverUri:
          'https://d1svz6cez3e2p4.cloudfront.net/Illustration/58d5242d-9e70-4f03-98b3-2697cea2a796/helenkeller_cover.jpg',
        storyType: 'Side quest',
      },
      {
        programWeek: 9,
        storyId: '61a63578-95d0-4427-a198-b757b00f63c3',
        storyTitle: 'Overall Assessment #3',
        storyType: 'Overall Assessment',
        thumbnailUri: '',
        coverUri: '',
      },
    ],
  ];
}
