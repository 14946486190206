import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';

export type SearchResultModel = {
  title: string;
  id: number | string;
};

function isAutocompleteOption(value: SearchResultModel): boolean {
  if (!value || typeof value === 'string') return false;
  return !!value.id;
}

function containsIdValidation(control: AbstractControl): ValidationErrors {
  return isAutocompleteOption(control.value) ? null : { required: true };
}

@Component({
  selector: 'app-search-program-field',
  templateUrl: './search-program-field.component.html',
  styleUrls: ['./search-program-field.component.scss'],
})
export class SearchProgramFieldComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() public programId: string | null = null;
  @Input() public placeholder: string;
  @Input() public options: SearchResultModel[];
  @Input() public onTouched: any = () => {};

  @Output() public focused = new EventEmitter();

  public searchResults: Observable<any>;
  public noResults: boolean = false;
  public isSearching: boolean = false;
  public inputControl: FormControl = new FormControl('', this.validators);

  constructor(
    @Optional() @Self() private controlDir: NgControl,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (this.controlDir) {
      this.controlDir.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    if (this.controlDir) {
      const control = this.controlDir.control;
      const validators = control.validator
        ? [control.validator, this.inputControl.validator]
        : this.inputControl.validator;
      control.setValidators(validators);
      control.updateValueAndValidity({ emitEvent: false });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      if (this.isSearching) {
        this.isSearching = false;
        if (
          !changes['options'].firstChange &&
          !changes['options'].currentValue.length
        ) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
      }
    }
  }

  public writeValue(obj: any): void {
    obj && this.inputControl.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.inputControl.valueChanges.subscribe({
      next: (value) => {
        if (typeof value === 'string') {
          this.isSearching = true;
          this.changeDetectorRef.detectChanges();
          fn(value);
        } else {
          fn(value);
        }
      },
    });
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  private get validators(): ValidatorFn[] {
    return [Validators.required, containsIdValidation];
  }

  public getTitle(item: SearchResultModel): string {
    return item?.title || '';
  }
}
