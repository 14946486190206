export class trainData {
  public static data = {
    stories: [
      {
        id: 1,
        parts: [
          {
            sentence: {
              text: 'I woke up thirsty in the middle of the night and went to get a glass of <span id="wrong">crayons</span>.',
            },
            word: { correct: 'water', wrong: 'crayons' },
          },
          {
            sentence: {
              text: 'I checked my alarm clock, which said it was <span id="wrong">noon</span>.',
            },
            word: { correct: 'midnight', wrong: 'noon' },
          },
          {
            sentence: {
              text: "I didn't want to wake anyone up, so I made sure to be very quiet.",
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'I tiptoed down the <span id="wrong">sky</span> and turned on a light in the kitchen.',
            },
            word: { correct: 'stairs', wrong: 'sky' },
          },
          {
            sentence: {
              text: 'I filled my glass with water.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'As I was heading back to <span id="wrong">school</span>, I saw a jar of cookies on the counter.',
            },
            word: { correct: 'bed', wrong: 'school' },
          },
          {
            sentence: {
              text: 'I couldn\'t reach it, so I <span id="wrong">slept</span> on a chair to grab it.',
            },
            word: { correct: 'stood', wrong: 'slept' },
          },
          {
            sentence: {
              text: 'I opened the lid and took a delicious chocolate chip cookie!',
            },
            word: { correct: null, wrong: null },
          },
        ],
      },
      {
        id: 2,
        parts: [
          {
            sentence: {
              text: 'The county fair was this weekend.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: ' Sam and his little brother Oliver were very excited to go.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'Their mom told them that if they wanted to go on rides, they should go before <span id="wrong">standing</span> lunch.',
            },
            word: { correct: 'eating', wrong: 'standing' },
          },
          {
            sentence: {
              text: '"Why?" Oliver asked.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: '"You\'ll see," said Mom.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'They waited until they reached the <span id="wrong">fall</span> of the line.',
            },
            word: { correct: 'front', wrong: 'fall' },
          },
          {
            sentence: {
              text: 'Sam and Oliver were buckled into seats, and then the ride spun them in circles very quickly.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'Sam was a little dizzy when he got off the ride and was glad that he had <span id="wrong">already</span> eaten lunch.',
            },
            word: { correct: 'not', wrong: 'already' },
          },
          {
            sentence: {
              text: 'After the ride, they played a game where they <span id="wrong">slept</span> darts at balloons to pop them.',
            },
            word: { correct: 'threw', wrong: 'slept' },
          },
          {
            sentence: {
              text: 'Sam won a stuffed dinosaur!',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'Finally, they ate chili for lunch and went home.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'It was a fun day at <span id="wrong">school</span>!',
            },
            word: { correct: 'the fair', wrong: 'school' },
          },
        ],
      },
      {
        id: 3,
        parts: [
          {
            sentence: {
              text: 'When you look up into the sky, sometimes you will be able to <span id="wrong">smell</span> the moon.',
            },
            word: { correct: 'see', wrong: 'smell' },
          },
          {
            sentence: {
              text: 'The moon does not make its own light.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'Instead, it reflects light from the <span id="wrong">moon</span>.',
            },
            word: { correct: 'sun', wrong: 'moon' },
          },
          {
            sentence: {
              text: 'As the moon and Earth move through space, different amounts of light from the moon reach Earth.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'This makes the moon look like it is changing shape over time.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'When the moon is full, it appears as a <span id="wrong">square</span>.',
            },
            word: { correct: 'circle', wrong: 'square' },
          },
          {
            sentence: {
              text: 'But sometimes, no light from the moon reaches Earth at all!',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'We call this a new moon.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'The moon is 250,000 <span id="wrong">water</span> away from Earth.',
            },
            word: { correct: 'miles', wrong: 'water' },
          },
          {
            sentence: {
              text: 'That\'s a long way to travel, but <span id="wrong">pencils</span> have walked on the moon!',
            },
            word: { correct: 'humans', wrong: 'pencils' },
          },
        ],
      },
      {
        id: 4,
        parts: [
          {
            sentence: {
              text: 'Have you heard the story of the three little pigs?',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'Each pig built a house.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'One made his house out of straw.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'One <span id="wrong">flew</span> his house out of sticks.',
            },
            word: { correct: 'made', wrong: 'flew' },
          },
          {
            sentence: {
              text: 'One made his house out of bricks.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'A hungry wolf came and tried to blow down the pigs\' <span id="wrong">socks</span>.',
            },
            word: { correct: 'houses', wrong: 'socks' },
          },
          {
            sentence: {
              text: 'The straw house blew down right away, and the first <span id="wrong">puppy</span> ran to his brother\'s house.',
            },
            word: { correct: 'pig', wrong: 'puppy' },
          },
          {
            sentence: {
              text: 'The wolf was also able to blow down the house made of sticks.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'Finally, the <span id="wrong">pig</span> tried to blow down the final house.',
            },
            word: { correct: 'wolf', wrong: 'pig' },
          },
          {
            sentence: {
              text: 'He huffed and puffed, but the <span id="wrong">snails</span> were so solid that he could not blow the house down!',
            },
            word: { correct: 'bricks', wrong: 'snails' },
          },
          {
            sentence: {
              text: 'The three little pigs were safe.',
            },
            word: { correct: null, wrong: null },
          },
        ],
      },
      {
        id: 5,
        parts: [
          {
            sentence: {
              text: 'Joy has a pet dog.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: "Her dog's name is Milo.",
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'Milo loves to play fetch.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'Joy throws the <span id="wrong">song</span> as far as she can, and Milo chases after it.',
            },
            word: { correct: 'ball', wrong: 'song' },
          },
          {
            sentence: {
              text: 'He catches the ball in his mouth and brings it back to <span id="wrong">Milo</span>.',
            },
            word: { correct: 'Joy', wrong: 'Milo' },
          },
          {
            sentence: {
              text: 'Milo also likes to do tricks.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'He can sit and stay and turn around in a circle.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'He loves to get treats when he does tricks.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'His favorite treats are ice cubes, because they are nice and <span id="wrong">warm</span>.',
            },
            word: { correct: 'cold', wrong: 'warm' },
          },
          {
            sentence: {
              text: 'Every day, Joy and Milo go on <span id="wrong">make</span> around the neighborhood.',
            },
            word: { correct: 'walks', wrong: 'make' },
          },
          {
            sentence: {
              text: 'Joy puts him on a leash and Milo visits all of their neighbors.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'But Milo\'s favorite activity is <span id="wrong">writing</span>.',
            },
            word: { correct: 'sleeping', wrong: 'writing' },
          },
          {
            sentence: {
              text: 'He dreams about chasing squirrels.',
            },
            word: { correct: null, wrong: null },
          },
        ],
      },
      {
        id: 6,
        parts: [
          {
            sentence: {
              text: 'Ron had been saving his allowance for a long time.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'He finally decided to open his piggy bank and count how much <span id="wrong">music</span> he had saved.',
            },
            word: { correct: 'money', wrong: 'music' },
          },
          {
            sentence: {
              text: 'He turned the piggy bank upside down and poured the coins out onto the table.',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'The coins made a loud noise when they hit the <span id="wrong">air</span>.',
            },
            word: { correct: 'table', wrong: 'air' },
          },
          {
            sentence: {
              text: 'Ron sorted them into pennies, nickels, dimes, and <span id="wrong">jumps</span>.',
            },
            word: { correct: 'quarters', wrong: 'jumps' },
          },
          {
            sentence: {
              text: 'When he counted it all, he realized he had saved six dollars and forty-two cents!',
            },
            word: { correct: null, wrong: null },
          },
          {
            sentence: {
              text: 'He thought about what he <span id="wrong">slept</span> to buy with all that money.',
            },
            word: { correct: 'wanted', wrong: 'slept' },
          },
          {
            sentence: {
              text: 'He gathered all the <span id="wrong">signs</span> and took them to the store.',
            },
            word: { correct: 'coins', wrong: 'signs' },
          },
        ],
      },
    ],
  };
}
