import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

const defaultXCoordinate = 0;
const defaultYCoordinate = 0;
const defaultZCoordinate = 0;

@Directive({
  selector: '[appPosition]',
})
export class PositionDirective implements OnChanges, OnInit {
  @Input() public x = defaultXCoordinate;
  @Input() public y = defaultYCoordinate;
  @Input() public z = defaultZCoordinate;

  constructor(private el: ElementRef) {}

  public ngOnInit(): void {
    this.position();
  }

  public ngOnChanges(): void {
    this.position();
  }

  private position(): void {
    const element = this.el.nativeElement;
    element.style.position = 'absolute';
    element.style.top = `${this.y}px`;
    element.style.left = `${this.x}px`;
    element.style.zIndex = this.z;
  }
}
