import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserRole } from '@app/@shared/models/user-role';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Credentials {
  // Customize received credentials here
  email: string;
  userId: string;
  token: string;
  exp: number;
  role: UserRole;
  sessionId: string;
  firstName: string;
  lastName: string;
  person_xid?: string;
}

const CREDENTIALS_KEY = 'auth';

/**
 * Provides storage for authentication credentials.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private readonly _credentials = new BehaviorSubject<Credentials | null>(null);

  constructor(private http: HttpClient) {
    const savedCredentials =
      sessionStorage.getItem(CREDENTIALS_KEY) ||
      localStorage.getItem(CREDENTIALS_KEY);
    if (savedCredentials) {
      this._credentials.next(JSON.parse(savedCredentials));
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  public isAuthenticated$ = this._credentials.pipe(map((creds) => !!creds));

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  public get isAuthenticated(): boolean {
    return !!this.credentials;
  }
  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials.value;
  }

  get isStudent(): boolean {
    return this._credentials.value.role === UserRole.STUDENT;
  }

  get isAdmin(): boolean {
    return this._credentials.value.role === UserRole.ADMIN;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  public setCredentials(credentials?: Credentials, remember?: boolean): void {
    this._credentials.next(credentials || null);
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(CREDENTIALS_KEY, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(CREDENTIALS_KEY);
      localStorage.removeItem(CREDENTIALS_KEY);
    }
  }

  public fetchCeresToken(): Observable<string> {
    return this.http
      .get<CeresTokenResponse>(environment.hubApi + '/auth/token')
      .pipe(map((response) => response.token));
  }
}

export interface CeresTokenResponse {
  token: string;
}
