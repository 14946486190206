import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { CredentialsService } from '@app/auth';
import { UserRole } from '@app/@shared/models/user-role';

@Injectable({
  providedIn: 'root',
})
export class AdminPanelGuard implements CanActivate {
  constructor(
    private credsService: CredentialsService,
    private router: Router
  ) {}

  public canActivate(): boolean | UrlTree {
    const creds = this.credsService.credentials;
    const redirect = this.router.parseUrl(``);

    if (creds) {
      return creds.role === UserRole.ADMIN ? true : redirect;
    }
    return redirect;
  }
}
