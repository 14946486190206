<div fxLayout="column" fxLayoutGap="20px">
  <div class="dialog-title" *ngIf="title">{{ title }}</div>

  <div>
    <div class="dialog-message">{{ message }}</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <ng-container *ngIf="no">
      <button class="btn btn-text light" (click)="onDismiss()">
        {{ no | uppercase }}
      </button>
    </ng-container>
    <ng-container *ngIf="yes">
      <button class="btn btn-primary" (click)="onConfirm()">
        {{ yes | uppercase }}
      </button>
    </ng-container>
  </div>
</div>
