import { Injectable } from '@angular/core';

export interface State {
  k: string;
  v: any;
}

export type ParamKey =
  | 'modal-landing-weekly-seen'
  | 'modal-landing-instructions-seen'
  | 'modal-reader-assessment-instructions-seen'
  | 'modal-reader-story-instructions-seen'
  | 'modal-home-instructions-seen'
  | 'modal-library-instructions-seen'
  | 'modal-madlibs-instructions-seen'
  | 'modal-puzzle-instructions-seen'
  | 'modal-reveal-instructions-seen'
  | 'modal-sticker-instructions-seen'
  | 'sticker-active-page-index'
  | 'sticker-panel-show'
  | 'sticker-active-pack-id'
  | 'teacher-dashboard-session-cohort-id'
  | 'ceres-dev-data'
  | 'student-avatar'
  | 'clubhouse-first-onboarding-seen'
  | 'clubhouse-second-onboarding-seen'
  | 'modal-story-player-seen';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private myStates: State[] = [];

  public add(paramKey: ParamKey, paramValue: any): void {
    this.getLocalStorage();
    if (this.get(paramKey)) {
      this.myStates.forEach((item: State, itemIndex: number) => {
        if (item.k === paramKey) {
          this.myStates.splice(itemIndex, 1);
        }
      });
    }
    this.myStates.push({ k: paramKey, v: paramValue });
    this.setLocalStorage();
  }

  public setLocalStorage(): void {
    localStorage.setItem('my-game-state', JSON.stringify(this.myStates));
  }

  public getLocalStorage(): void {
    const raw = localStorage.getItem('my-game-state');

    if (raw !== null && raw !== undefined && raw !== '') {
      this.myStates = JSON.parse(raw);
    }
  }

  public get(paramKey: ParamKey): any {
    this.getLocalStorage();

    if (this.myStates.length !== 0) {
      return (
        this.myStates.filter((item: State) => item.k === paramKey)[0] || null
      );
    }
    return null;
  }

  public remove(paramKey: ParamKey): void {
    this.getLocalStorage();
    if (this.get(paramKey)) {
      this.myStates.forEach((item: State, itemIndex: number) => {
        if (item.k === paramKey) {
          this.myStates.splice(itemIndex, 1);
        }
      });
    }
    localStorage.setItem('my-game-state', JSON.stringify(this.myStates));
  }
}
