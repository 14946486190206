// Add helper function here

export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
): { [key: string]: T[] } =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

export function formatBytes(bytes, decimals = 2): string {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function generateRandomString(length: number): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  return [...Array(length).keys()].reduce(
    (result) =>
      result + characters.charAt(Math.floor(Math.random() * characters.length)),
    ''
  );
}

export const calendarMondayFilter = (d: Date | null): boolean => {
  const day = (d || new Date()).getDay();
  return day === 1;
};

export function random<T>(array: T[]): T | undefined {
  if (array.length === 0) {
    return undefined;
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export function randomItems<T>(array: T[], amount: number): T[] {
  return array
    .sort(() => Math.random() - 0.5)
    .slice(0, Math.min(amount, array.length));
}

export function arrayFromRange(
  start: number,
  end: number,
  step: number = 1
): number[] {
  const length = Math.floor((end - start) / step) + 1;
  return Array.from({ length }, (_, index) => start + index * step);
}

export function inputIsNotNullOrUndefined<T>(
  input: null | undefined | T
): input is T {
  if (Array.isArray(input)) {
    return input.every((i) => i !== null && i !== undefined);
  }
  return input !== null && input !== undefined;
}

export function inputIsTrue(input: null | undefined | boolean): boolean {
  return input === true;
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export function navigateToExternalUrl(url: string): void {
  window.location.href = url;
}

export function flattenArrayReduce<T>(array: T[][]): T[] {
  return array.reduce((flattened, subArray) => flattened.concat(subArray), []);
}
