import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { CredentialsService } from '@app/auth';
import { UserRole } from '@app/@shared/models/user-role';

const homePage = {
  [UserRole.ADMIN]: '/admin-panel',
  [UserRole.TEACHER]: '/teacher-dashboard',
  [UserRole.STUDENT]: '/home',
};

@Injectable({
  providedIn: 'root',
})
export class HomePageGuard implements CanActivate {
  constructor(
    private credsService: CredentialsService,
    private router: Router
  ) {}

  public canActivate(): boolean | UrlTree {
    const creds = this.credsService.credentials;
    return this.router.parseUrl(homePage[creds?.role] || '/login');
  }
}
