import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Credentials, CredentialsService } from './credentials.service';
import { environment } from '@env/environment';
import { User } from '@app/@shared/models/user';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient
  ) {}

  private _onLogin: Subject<void> = new Subject();
  private _onLogout: Subject<void> = new Subject();

  public onLogin = this._onLogin.asObservable();
  public onLogout = this._onLogout.asObservable();

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  public loginWithCredentials(
    context: LoginContext
  ): Observable<LoginResponse> {
    const request = this.http.post<any>(`${environment.hubApi}/auth/login`, {
      email: context.username,
      password: context.password,
    });

    return this.login(request);
  }

  public loginWithToken(
    userId: string,
    token: string
  ): Observable<LoginResponse> {
    const request = this.http.post<any>(`${environment.hubApi}/auth/token`, {
      userId,
      token,
    });

    return this.login(request);
  }

  private login(request: Observable<any>): Observable<any> {
    return request.pipe(
      tap(
        (response) => {
          const decodedHubApiToken: any = JSON.parse(
            atob(response.token.split('.')[1])
          );
          this.credentialsService.setCredentials(
            {
              email: decodedHubApiToken.email,
              userId: decodedHubApiToken.id,
              token: response.token.replace('bearer ', ''),
              exp: decodedHubApiToken.expiresAt,
              role: decodedHubApiToken.role,
              sessionId: decodedHubApiToken.sessionId,
              firstName: decodedHubApiToken.firstName,
              lastName: decodedHubApiToken.lastName,
              person_xid: response.user.person_xid,
            },
            true
          );
          this._onLogin.next();
        },
        () => throwError({ error: 'unauthorized' })
      )
    );
  }

  /**
   * Logs out the user and clear credentials and player
   * @return True if the user was logged out successfully.
   */

  public clearUser(): void {
    this.credentialsService.setCredentials();
  }

  public logout(): Observable<boolean> {
    this._onLogout.next();
    this.clearUser();
    return of(true);
  }
}

export interface LoginResponse {
  credentials: Credentials;
  user: User;
}
