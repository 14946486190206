import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export interface FileUploadResponse {
  filename: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private http: HttpClient) {}

  public uploadFile(file: File): Observable<FileUploadResponse> {
    const formData = new FormData();
    formData.append('fileUpload', file, file.name);
    return this.http.post<FileUploadResponse>(
      `${environment.hubApi}/blob`,
      formData
    );
  }
}
